import {
  ANALYTICS_ACTION_CLICKED,
  ANALYTICS_SUBJECT_BUTTON,
  TRACK_EVENT_TYPE,
  UI_EVENT_TYPE,
} from '@atlassian/mpac-analytics';
import { allManagePartnersRoutes } from '@atlassian/mpac-partners';
import { log } from '@atlassian/mpac-utils';
import { userType } from '@atlassiansox/analytics-web-client';
import Cookies from 'js-cookie';
import { isEmpty } from 'lodash';

import bootstrapConfig from '../../../app/bootstrapConfig';
import { getCanonicalUrl, getOptimizelyCampaigns, maskEmails, maskURL } from './utils';

import type { AnalyticsEventPayload } from '@atlaskit/analytics-next';

export const EVENT_CONTAINER_APP_HEADER = 'appHeader';
export const EVENT_CONTAINER_NAV_BAR = 'navBar';
export const EVENT_CONTAINER_SITE_SELECTOR = 'siteSelector';
export const EVENT_CONTAINER_APP_LISTING_PRICING_TAB = 'appListingPricingTab';
export const EVENT_CONTAINER_CERTIFICATION_SECTION = 'certificationSection';

const EVENT_CONTAINER_ADDON_DISCOVERY = 'addonDiscovery';

export const isPartnerPageEvent = (eventSource: string) => {
  const pattern = /^(managepartner|manageApp|appVersionPublishView)/i;

  return (
    pattern.test(eventSource) || allManagePartnersRoutes.some((item) => item?.name === eventSource)
  );
};

/*
  sanitizeReferrer removes “text” query parameter from the referrer string as it may contain UGC/PII data.
  [Example]: partner reports screen events contained referrer string with value text=@gmail.com
 */
export const sanitizeReferrer = (referrer) => {
  if (isEmpty(referrer)) return referrer;

  const url = new URL(referrer);
  const search = new URLSearchParams(url.search);

  search.delete('text');

  url.search = isEmpty(search.toString()) ? '' : `?${search.toString()}`;

  return url.href;
};

/*
  sanitizeAdditionalProperties removes “text” key from the filters object as it may contain UGC/PII data.
  [Example]: partner reports screen events contained filters object with value text: @gmail.com
 */
export const sanitizeAdditionalProperties = (additionalProperties) => {
  const properties = { ...additionalProperties };

  delete properties.filters?.text;

  return properties;
};

// eslint-disable-next-line complexity
export const generateOtherAtrributes = (
  windowHref: string | null,
  documentReferrer: string | null,
  documentTitle: string | null
) => {
  const page = windowHref ? new URL(windowHref) : null;
  const page_url = page ? page.href : '';
  const page_referrer = documentReferrer ? new URL(documentReferrer) : null;
  return {
    page_title: documentTitle,
    page_url,
    page_domain: page?.hostname || '',
    page_subdomain: page?.hostname || '',
    page_path: page?.pathname || '',
    page_url_parameters: page?.search || '',
    page_referrer_url: page_referrer?.href || '',
    page_referrer_domain: page_referrer?.hostname || '',
    page_referrer_subdomain: page_referrer?.hostname || '',
    page_referrer_path: page_referrer?.pathname || '',
    userAgent: navigator?.userAgent,
    browserLanguages: navigator?.languages,
    page_canonical_url: maskURL(getCanonicalUrl(windowHref)) || '',
    // Thread for more clarity on "isMarketingEvent" -> https://atlassian.slack.com/archives/C01U0UGBZJB/p1671134181487119
    isMarketingEvent: true,
    page_url_params_json: page
      ? JSON.stringify(Object.fromEntries(new URLSearchParams(page.search)))
      : '{}',
    page_referrer_params_json: page_referrer
      ? JSON.stringify(Object.fromEntries(new URLSearchParams(page_referrer.search)))
      : '{}',
    optimizelyCampaigns: getOptimizelyCampaigns(),
  };
};

// eslint-disable-next-line max-lines-per-function
export const sendTrackEvent = ({
  eventContainer,
  eventComponent,
  eventName,
  actionSubjectId,
  event,
  label,
  statsig,
  ...additionalProperties
}) => {
  try {
    const { featureFlags, launchDarklyUserKey } = bootstrapConfig.get();
    const maskedAdditionalProperties = maskEmails(additionalProperties);
    const maskedFeatureFlags = maskEmails(featureFlags);

    const { attributes, ...allAttributes } = maskedAdditionalProperties;
    const actualSource = getAndRemoveSourceOutOfAttributes(maskedAdditionalProperties);
    if (actualSource) {
      eventContainer = actualSource;
    }
    window?.ace?.analytics.Tracking.sendGasV3TrackEvent(event, eventComponent, actionSubjectId, {
      eventType: TRACK_EVENT_TYPE,
      eventContainer,
      actionSubjectId,
      event,
      label,
      schemaVersion: 1,
      featureFlags: { ...maskedFeatureFlags, statsig: maskEmails(statsig) },
      launchDarklyUserKey,
      ...attributes,
      ...allAttributes,
    });
  } catch (error) {
    console.error('ace.analytics.Tracking.sendGasV3TrackEvent threw an error', error);
  }
};

// eslint-disable-next-line max-lines-per-function
export const sendUIEvent = ({
  eventContainer,
  eventComponent,
  eventName,
  actionSubjectId,
  event,
  label,
  statsig = {},
  ...additionalProperties
}) => {
  /*
     If isPartnerPageEvent is true, then partner events will be triggered from analytics-web-client.
   */
  if (!isPartnerPageEvent(eventContainer)) {
    try {
      const { featureFlags, launchDarklyUserKey } = bootstrapConfig.get();
      const maskedAdditionalProperties = maskEmails(additionalProperties);
      const maskedFeatureFlags = maskEmails(featureFlags);

      const actualSource = getAndRemoveSourceOutOfAttributes(maskedAdditionalProperties);
      if (eventContainer) maskedAdditionalProperties.eventSource = eventContainer;
      window?.ace?.analytics.Tracking.trackEvent(eventName, {
        eventType: UI_EVENT_TYPE,
        eventContainer: actualSource,
        eventComponent,
        actionSubjectId,
        event,
        label,
        schemaVersion: 1,
        featureFlags: { ...maskedFeatureFlags, statsig: maskEmails(statsig) },
        launchDarklyUserKey,
        ...maskedAdditionalProperties,
      });
    } catch (error) {
      console.error('ace.analytics.Tracking.trackEvent threw an error', error);
    }
  } else {
    try {
      const { featureFlags, launchDarklyUserKey, analyticsClient } = bootstrapConfig.get();
      const maskedAdditionalProperties = maskEmails(additionalProperties);
      const maskedFeatureFlags = maskEmails(featureFlags);

      const actualSource = getAndRemoveSourceOutOfAttributes(maskedAdditionalProperties);
      const { attributes, ...allAttributes } = maskedAdditionalProperties;

      let otherAttributes = {};
      if (!isPartnerPageEvent(eventContainer)) {
        otherAttributes = generateOtherAtrributes(
          window?.location.href,
          document?.referrer,
          document?.title
        );
      }

      if (!eventContainer) {
        log.error(
          'ERROR: webAnalytics: missing source in UI Event: ',
          JSON.stringify({
            eventComponent,
            actionSubjectId,
            event,
            additionalProperties,
          })
        );
      }

      analyticsClient.sendUIEvent({
        source: actualSource || 'unknown',
        actionSubject: eventComponent || 'unknown',
        action: event,
        actionSubjectId,

        attributes: {
          label,
          actionSubjectId,
          eventName,
          schemaVersion: 1,
          featureFlags: { ...maskedFeatureFlags, statsig: maskEmails(statsig) },
          launchDarklyUserKey,
          eventContainer,
          ...allAttributes,
          ...attributes,
          ...otherAttributes,
          timeStamp: new Date().getTime(),
          client: 'AnalyticsWebClient',
        },
      });
    } catch (error) {
      log.error('analytics.web.client.sendUIEvent threw an error', error, actionSubjectId);
    }
  }
};

// eslint-disable-next-line complexity
export const sendScreenEvent = (referrer, name, additionalProperties) => {
  /*
     If isPartnerPageEvent is true, then partner events will be triggered from analytics-web-client.
   */
  if (!isPartnerPageEvent(name)) {
    const bootstrap = bootstrapConfig.get();
    const maskedAdditionalProperties = maskEmails(additionalProperties);
    const maskedFeatureFlags = maskEmails(bootstrap.featureFlags);

    if (window.ace && window.ace.analytics && window.ace.mkt) {
      try {
        window?.ace?.analytics.Tracking.pageView(name, name, {
          referrer: maskEmails(referrer),
          context_atl_path: window.ace.mkt.Cookie.readCookie('__atl_path') || '',
          utmz: window.ace.mkt.Cookie.readCookie('__utmz') || '',
          ga_clientid: window.ace.mkt.Cookie.readCookie('_ga') || '',
          featureFlags: maskedFeatureFlags,
          launchDarklyUserKey: bootstrap.launchDarklyUserKey,
          screenNameOverride: name,
          ...maskedAdditionalProperties,
        });
      } catch (error) {
        console.error('ace.analytics.Tracking.pageView threw an error', error);
      }
    }
  } else {
    const { analyticsClient, featureFlags, launchDarklyUserKey } = bootstrapConfig.get();
    const maskedAdditionalProperties = maskEmails(additionalProperties);
    const maskedFeatureFlags = maskEmails(featureFlags);
    const userIdCookie = Cookies.get('__aid_user_id');

    if (userIdCookie) {
      analyticsClient.setUserInfo(userType.ATLASSIAN_ACCOUNT, userIdCookie);
    }
    let otherAttributes = {};
    if (!isPartnerPageEvent(name)) {
      otherAttributes = generateOtherAtrributes(
        window?.location.href,
        document?.referrer,
        document?.title
      );
    }

    try {
      analyticsClient.sendScreenEvent({
        name,
        attributes: {
          name,
          referrer: sanitizeReferrer(referrer),
          featureFlags: maskedFeatureFlags,
          launchDarklyUserKey,
          screenNameOverride: name,
          ...sanitizeAdditionalProperties(maskedAdditionalProperties),
          ...otherAttributes,
          timeStamp: new Date().getTime(),
          client: 'AnalyticsWebClient',
        },
      });
    } catch (error) {
      console.error('analyticsWebClient.screenEvent threw an error', error);
    }
  }
};

export const sendOperationalEvent = async (payload: AnalyticsEventPayload, statsig = {}) => {
  const { analyticsClient } = bootstrapConfig.get();
  const maskedAdditionalProperties = maskEmails(payload.attributes);
  const maskedFeatureFlags = maskEmails(statsig);
  const userIdCookie = Cookies.get('__aid_user_id');

  if (userIdCookie) {
    analyticsClient.setUserInfo(userType.ATLASSIAN_ACCOUNT, userIdCookie);
  }

  try {
    await analyticsClient.sendOperationalEvent({
      action: payload.eventType.action,
      actionSubject: payload.eventType.actionSubject,
      actionSubjectId: payload.actionSubjectId,
      attributes: {
        ...maskedAdditionalProperties,
        featureFlags: maskedFeatureFlags,
      },
      source: payload.source || payload.eventContainer,
      tags: payload.tags,
    });
  } catch (error) {
    console.error('analyticsWebClient.operationalEvent threw an error', error);
  }
};

const getAndRemoveSourceOutOfAttributes = (attributes) => {
  let actualSource;
  if (attributes.source) {
    actualSource = attributes.source;
    delete attributes.source;
  }
  return actualSource;
};

export const trackAppListing = ({
  eventContainer,
  eventComponent,
  eventName,
  actionSubjectId,
  event,
  label,
  appKey,
  hosting,
  ...additionalProperties
}) =>
  sendUIEvent({
    eventContainer,
    eventComponent,
    eventName,
    actionSubjectId,
    event,
    label,
    category: 'appListing',
    appKey,
    hosting,
    ...additionalProperties,
  });

export const trackTryModal = ({ appKey, hosting, paymentModel }) =>
  trackAppListing({
    eventContainer: EVENT_CONTAINER_APP_HEADER,
    eventComponent: ANALYTICS_SUBJECT_BUTTON,
    eventName: 'Clicked-Try-Modal-Trigger',
    actionSubjectId: 'tryModalTrigger',
    event: ANALYTICS_ACTION_CLICKED,
    label: 'Try it free',
    appKey,
    source: 'appListingPage',
    hosting,
    paymentModel,
  });

export const trackBuyModal = ({ appKey, hosting, paymentModel }) =>
  trackAppListing({
    eventContainer: EVENT_CONTAINER_APP_HEADER,
    eventComponent: ANALYTICS_SUBJECT_BUTTON,
    eventName: 'Clicked-Buy-Modal-Trigger',
    actionSubjectId: 'buyModalTrigger',
    event: ANALYTICS_ACTION_CLICKED,
    label: 'Buy it now',
    appKey,
    source: 'appListingPage',
    hosting,
    paymentModel,
  });

export const trackTryButton = ({ appKey, hosting, paymentModel }) =>
  trackAppListing({
    eventContainer: EVENT_CONTAINER_APP_HEADER,
    eventComponent: ANALYTICS_SUBJECT_BUTTON,
    eventName: 'Clicked-Try',
    actionSubjectId: 'installButton',
    event: ANALYTICS_ACTION_CLICKED,
    label: 'Start Free Trial',
    appKey,
    source: 'selectHostingModel',
    hosting,
    paymentModel,
  });

export const trackBuyButton = ({ appKey, hosting, paymentModel }) =>
  trackAppListing({
    eventContainer: EVENT_CONTAINER_APP_HEADER,
    eventComponent: ANALYTICS_SUBJECT_BUTTON,
    eventName: 'Clicked-Buy',
    actionSubjectId: 'installButton',
    event: ANALYTICS_ACTION_CLICKED,
    label: 'Subscribe Now',
    appKey,
    source: 'selectHostingModel',
    hosting,
    paymentModel,
  });

export const trackAddonDiscovery = ({
  eventName,
  actionSubject,
  actionSubjectId,
  action,
  label,
  payload,
}) => {
  sendUIEvent({
    eventContainer: EVENT_CONTAINER_ADDON_DISCOVERY,
    eventComponent: actionSubject,
    eventName,
    actionSubjectId,
    event: action,
    label,
    category: 'addonDiscovery',
    ...payload,
    ...payload.attributes,
  });
};

// Manage apps track events
export const trackManageApps = ({
  eventContainer,
  actionSubject,
  action,
  actionSubjectId,
  ...additionalProperties
}) =>
  // @ts-expect-error [MC-2850] - TS2345 - Argument of type '{ eventContainer: any; eventComponent: any; event: any; actionSubjectId: any; category: string; }' is not assignable to parameter of type '{ [x: string]: any; eventContainer: any; eventComponent: any; eventName: any; actionSubjectId: any; event: any; label: any; }'.
  sendUIEvent({
    eventContainer,
    eventComponent: actionSubject,
    event: action,
    actionSubjectId,
    category: 'manageApps',
    ...additionalProperties,
  });

// Manage partner pages track events
export const trackManagePartnerAccount = ({
  eventContainer,
  actionSubject,
  action,
  actionSubjectId,
  ...additionalProperties
}) => {
  // @ts-expect-error [MC-2850] - TS2339 - Property 'vendorData' does not exist on type 'Readonly<{ adminHubBaseUrl: string; graphQLGatewayUrl: string; mpacBaseUrl: string; mpacCdnBaseUrl: string; macBaseUrl: string; wacBaseUrl: string; atlassianCommunityBaseUrl: string; jiraServiceDeskEmbedBaseUrl: string; ... 8 more ...; analyticsClient: AnalyticsClientInterface; }>'.
  const vendorData = bootstrapConfig.get().vendorData;
  const commonVendorAttributes =
    vendorData?.id && vendorData?.name
      ? {
          partnerId: vendorData.id,
          partnerName: vendorData.name,
        }
      : {};
  // @ts-expect-error [MC-2850] - TS2345 - Argument of type '{ partnerId: any; partnerName: any; eventContainer: any; eventComponent: any; event: any; actionSubjectId: any; category: string; } | { partnerId?: undefined; partnerName?: undefined; eventContainer: any; eventComponent: any; event: any; actionSubjectId: any; category: string; }' is not assignable to parameter of type '{ [x: string]: any; eventContainer: any; eventComponent: any; eventName: any; actionSubjectId: any; event: any; label: any; }'.
  sendUIEvent({
    eventContainer,
    eventComponent: actionSubject,
    event: action,
    actionSubjectId,
    category: 'managePartner',
    ...commonVendorAttributes,
    ...additionalProperties,
  });
};
