import { flowUtils } from '@atlassian/mpac-utils';
const GRID_SIZE = 8;
const toPixels = value => "".concat(value, "px");
export const xSmall = 'xs';
export const small = 'sm';
export const medium = 'md';
export const large = 'lg';
export const xLarge = 'xl';
/**
 * @see https://atlassian.design/foundations/grid#gutter-spacing
 */
export const GutterSpacingUnitless = {
  Base: GRID_SIZE * 2,
  Cozy: GRID_SIZE * 2,
  Compact: GRID_SIZE / 2,
  Comfortable: GRID_SIZE * 5
};

/**
 * @unit pixels
 * @see https://atlassian.design/foundations/grid#gutter-spacing
 */
export const GutterSpacing = {
  Base: toPixels(GutterSpacingUnitless.Base),
  Cozy: toPixels(GutterSpacingUnitless.Cozy),
  Compact: toPixels(GutterSpacingUnitless.Compact),
  Comfortable: toPixels(GutterSpacingUnitless.Comfortable)
};
export const foldScreenClass = sc => (xs, sm, md, lg, xl) => {
  switch (sc) {
    case 'xs':
      return xs;
    case 'sm':
      return sm;
    case 'md':
      return md;
    case 'lg':
      return lg;
    case 'xl':
      return xl;
    case 'xxl':
      return xl;
    default:
      {
        throw flowUtils.unexpectedCase(sc);
      }
  }
};
export const triFoldScreenClass = sc => (sm, md, lg) => foldScreenClass(sc)(sm, sm, md, lg, lg);