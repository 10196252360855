import { token } from '@atlaskit/tokens';
import { useStatsig } from '@atlassian/mpac-feature-flags';
import {
  ChevronDownIcon,
  ChevronDownIconWC,
  ChevronUpIcon,
  ChevronUpIconWC,
} from '@atlassian/mpac-primitives';
import { styled } from '@compiled/react';
import React, { forwardRef } from 'react';

import { TEST_IDS } from '../../../../common/testIds';
import { BaseButton, Text, TextWC } from './Button.styles';

const Button = styled(BaseButton)<{ isSelected: boolean }>({
  padding: `${token('space.050')} ${token('space.075')}`,
  gap: token('space.075'),
  color: (props) => (props.isSelected ? token('color.text.selected') : token('color.text.subtle')),

  '& path': {
    fill: (props) => (props.isSelected ? token('color.icon.selected') : token('color.icon.subtle')),
  },
  '&:hover path': {
    fill: token('color.icon.subtle'),
  },
  '&:hover': {
    color: token('color.text.subtle'),
  },
  '&:active': {
    color: token('color.text.subtle'),
  },
});

const ButtonWC = styled(BaseButton)<{ isSelected: boolean }>({
  padding: `${token('space.100')} ${token('space.200')}`,
  gap: token('space.100'),
  display: 'inline-flex',
  alignItems: 'center',
  backgroundColor: 'transparent',
  color: (props) => (props.isSelected ? token('color.text.selected') : token('color.text')),

  '& path': {
    fill: (props) => (props.isSelected ? token('color.icon.selected') : token('color.icon')),
  },
  '&:hover path': {
    fill: token('color.icon.selected'),
    backgroundColor: 'transparent',
  },
  '&:hover': {
    color: token('color.text.selected'),
    backgroundColor: 'transparent',
  },
  '&:active': {
    color: token('color.text.selected'),
    backgroundColor: 'transparent',
  },
});

const MenuButtonContainer = styled.div<{ isSelected: boolean }>(
  {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    position: 'relative',
  },
  (props) =>
    props.isSelected && {
      '&::after': {
        height: '3px',
        position: 'absolute',
        backgroundColor: token('color.border.selected'),
        borderRadius: '1px 1px 0px 0px',
        content: '',
        insetBlockEnd: '0px',
        insetInlineEnd: token('space.050'),
        insetInlineStart: token('space.050'),
      },
    }
);

const MenuButtonContainerWC = styled.div({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
});

export const MenuButtonDesktop = forwardRef<
  HTMLButtonElement,
  {
    onClick: () => void;
    label: string;
    isActive: boolean;
    ariaLabel: string;
  }
>(({ onClick, label, isActive, ariaLabel }, buttonRef) => {
  const { enableMpacWebCoherence } = useStatsig();

  if (enableMpacWebCoherence()) {
    return (
      <MenuButtonContainerWC>
        <ButtonWC
          data-testId={TEST_IDS.MENU_BUTTON_DESKTOP}
          ref={buttonRef}
          onClick={onClick}
          isSelected={isActive}
          aria-label={ariaLabel}
          aria-expanded={isActive}
        >
          <TextWC>{label}</TextWC>
          {isActive ? (
            <ChevronUpIconWC height="30" width="10" />
          ) : (
            <ChevronDownIconWC height="30" width="10" />
          )}
        </ButtonWC>
      </MenuButtonContainerWC>
    );
  }
  return (
    <MenuButtonContainer isSelected={isActive}>
      <Button
        data-testId={TEST_IDS.MENU_BUTTON_DESKTOP}
        ref={buttonRef}
        onClick={onClick}
        isSelected={isActive}
        aria-label={ariaLabel}
        aria-expanded={isActive}
      >
        <Text>{label}</Text>
        {isActive ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </Button>
    </MenuButtonContainer>
  );
});

MenuButtonDesktop.displayName = 'MenuButtonDesktop';
