import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import commonMapDispatchToProps from '../../../container/commonMapDispatchToProps';
import commonMapStateToProps from '../../../container/commonMapStateToProps';
import Routes from '../components/Routes';

function mapStateToProps(state) {
  return {
    ...commonMapStateToProps(state),
  };
}

const mapDispatchToProps = (dispatch) => ({
  ...commonMapDispatchToProps(dispatch),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(Routes)) as any;
