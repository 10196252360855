import { NotFound } from '@atlassian/mpac-primitives';
import { RedirectExternal, useLocation } from '@atlassian/mpac-ui';

import SEOFriendlyRedirect from '../../../common/components/SEOFriendlyRedirect';

export const PROJECT_MANAGEMENT = '/projectmanagement';
export const TEST_CASE_MANAGEMENT = '/testcasemanagement';
export const TIME_TRACKING = '/timetracking';
export const WIKI_STYLING = '/wikistyling';
export const SOLUTIONS = '/solutions';
export const CODEGEIST = '/codegeist';
export const CODEGEIST_WINNERS = '/codegeist/winners';
export const WINNERS = '/winners';

export const staticRedirectRoutesMap = {
  [PROJECT_MANAGEMENT]: {
    screen: 'categoriesScreen',
    redirectPath: '/categories/project-management',
    isExternal: false,
  },
  [TEST_CASE_MANAGEMENT]: {
    screen: 'categoriesScreen',
    redirectPath: '/categories/test-management',
    isExternal: false,
  },
  [TIME_TRACKING]: {
    screen: 'categoriesScreen',
    redirectPath: '/categories/time-tracking',
    isExternal: false,
  },
  [WIKI_STYLING]: {
    screen: 'categoriesScreen',
    redirectPath: '/categories/themes-styles',
    isExternal: false,
  },
  [CODEGEIST]: {
    screen: 'codegeist',
    redirectPath: 'https://go.atlassian.com/codegeist',
    isExternal: true,
  },
  [CODEGEIST_WINNERS]: {
    screen: 'codegeist_winners',
    redirectPath: 'https://go.atlassian.com/codegeist',
    isExternal: true,
  },
  [WINNERS]: {
    screen: 'winners',
    redirectPath: 'https://go.atlassian.com/codegeist',
    isExternal: true,
  },
  [SOLUTIONS]: {
    screen: 'homeScreen',
    redirectPath: '/',
    isExternal: false,
  },
};

export const StaticRedirectRoute = () => {
  const params = useLocation();
  const { pathname = '/' } = params;

  const redirectPath = staticRedirectRoutesMap[pathname]?.redirectPath;
  const isExternal = staticRedirectRoutesMap[pathname]?.isExternal;

  if (redirectPath) {
    if (isExternal) {
      return <RedirectExternal url={redirectPath} statusCode={302} />;
    }
    return <SEOFriendlyRedirect to={{ pathname: redirectPath }} statusCode={301} />;
  }
  return <NotFound />;
};
