import {
  getAllMarketplaceContexts,
  getMergedMarketplaceContext,
  OPERATIONAL_EVENT_TYPE,
  SCREEN_EVENT_TYPE,
  TRACK_EVENT_TYPE,
} from '@atlassian/mpac-analytics';
import { Map } from 'immutable';

import { trackEvent } from '../../../../common/utils/analytics/analytics';
import {
  sendOperationalEvent,
  sendScreenEvent,
  sendTrackEvent,
  sendUIEvent,
  trackAddonDiscovery,
} from '../../../../common/utils/analytics/webAnalytics';

import type { AnalyticsEventPayload } from '@atlaskit/analytics-next';

export const AllowedChannels = Object.freeze({
  AddonDiscovery: 'addonDiscovery',
  Marketplace: 'marketplace',
});

type EventDetails = {
  event: AnalyticsEventPayload;
  channel?: string;
  flags: object;
  experiments: object;
};

export const getValuesFromContext = ({ payload = {}, context }: AnalyticsEventPayload) => {
  const marketplaceContexts = getAllMarketplaceContexts(context);
  const gaValue = marketplaceContexts.reduce(
    (value, contextItem) =>
      contextItem.attributes.gaValueKey
        ? Map(payload).getIn(contextItem.attributes.gaValueKey)
        : value,
    null
  );
  const gaCategory = marketplaceContexts.reduce(
    (category, contextItem) => contextItem.attributes.gaCategory ?? category,
    'AddonDiscovery'
  );

  return { gaValue, gaCategory };
};

export const sendAddonDiscoveryEvent = ({ event, flags, experiments }: EventDetails) => {
  const { payload = {} } = event;
  const {
    objectType,
    objectId,
    eventType: { actionSubject, action, actionSubjectId, type },
  } = payload;
  const { gaValue, gaCategory } = getValuesFromContext(event);

  const label = payload.label ?? [objectType, objectId].filter(Boolean).join(':');
  const gaAction = actionSubjectId
    ? `${actionSubject} ${action} ${actionSubjectId}`
    : `${actionSubject} ${action}`;

  trackEvent(gaCategory, gaAction, label, gaValue);

  if (type === TRACK_EVENT_TYPE) {
    sendTrackEvent({
      eventContainer: payload.source || payload.eventContainer,
      eventComponent: actionSubject,
      eventName: `${actionSubject} ${action}`,
      actionSubjectId,
      event: action,
      label,
      statsig: {
        flags,
        experiments,
      },
      category: 'addonDiscovery',
      ...payload,
      ...payload.attributes,
    });
  } else {
    trackAddonDiscovery({
      eventName: `${actionSubject} ${action}`,
      actionSubject,
      actionSubjectId,
      action,
      label,
      payload,
    });
  }
};

export const sendMarketplaceEvent = ({ event, flags, experiments }: EventDetails) => {
  const { payload = {}, context } = event;
  const { eventType = {}, actionSubjectId, eventContainer, source } = payload;
  const { actionSubject, action, type } = eventType;

  const { properties, attributes: marketplaceContextAttributes } =
    getMergedMarketplaceContext(context);

  if (type === TRACK_EVENT_TYPE) {
    sendTrackEvent({
      eventContainer: payload.source || payload.eventContainer,
      eventComponent: actionSubject,
      eventName: `${actionSubject} ${action}`,
      actionSubjectId,
      event: action,
      source,
      statsig: {
        flags,
        experiments,
      },
      category: 'addonDiscovery',
      ...payload,
      ...payload.attributes,
      ...marketplaceContextAttributes,
      ...properties,
    });
  } else if (type === SCREEN_EVENT_TYPE) {
    sendScreenEvent(payload.referer, payload.actionSubjectId, {
      ...marketplaceContextAttributes,
      ...payload.attributes,
      ...properties,
    });
  } else if (type === OPERATIONAL_EVENT_TYPE) {
    sendOperationalEvent(payload, {
      flags,
      experiments,
    });
  } else {
    sendUIEvent({
      eventName: `${actionSubject} ${action}`,
      eventComponent: actionSubject,
      event: action,
      actionSubjectId,
      eventContainer,
      source,
      statsig: {
        flags,
        experiments,
      },
      poweredBy: 'AMKT_FRONTEND',
      ...marketplaceContextAttributes,
      ...payload.attributes,
      ...properties,
    });
  }
};
