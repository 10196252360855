import {
  BlockedPartnerAccessDetectionMiddleware,
  CacheMiddleware,
  ClientSchema,
  orchestrationMpacConfig,
  TwoStepAuthTimedOutMiddleware,
} from '@atlassian/mpac-services-client';
import {
  createApolloCache,
  createApolloClient,
} from '@atlassian/mpac-services-client/apollo-graphql-gateway';
import { createBrowserHistory } from 'history';

import { APOLLO_CLIENT_NAME, APOLLO_CLIENT_VERSION } from '../constants';
import { createStore, initializeStore } from '../store';

import type { BootstrapConfig } from '../../application-config/types/BootstrapConfig';
import type { Store } from '../store';
import type { StatsigClientType } from '@atlassian/mpac-feature-flags';
import type { ApolloGraphQLGatewayClient } from '@atlassian/mpac-services-client/apollo-graphql-gateway';
// @ts-expect-error [MC-2850] - TS2614 - Module '"history/createBrowserHistory"' has no exported member 'BrowserHistory'. Did you mean to use 'import BrowserHistory from "history/createBrowserHistory"' instead?
import type { BrowserHistory } from 'history/createBrowserHistory';

export const initAceAnalytics = (bootstrapConfig: BootstrapConfig) => {
  if (!window.ace) return;

  const { featureFlags, analytics } = bootstrapConfig;

  window.ace.analytics.Initializer.initWithPageAnalytics(analytics.aceAnalytics.writeKey, {
    featureFlags,
    poweredBy: 'AMKT_FRONTEND',
    gasv3Product: 'marketplace',
  });
};

const getApolloCache = () => {
  const shouldHydrate =
    // @ts-expect-error [MC-2850] - TS2339 - Property '__INITIAL_STATE__' does not exist on type 'Window & typeof globalThis'.
    typeof window.__INITIAL_STATE__ !== 'undefined' &&
    // @ts-expect-error [MC-2850] - TS2339 - Property '__INITIAL_STATE__' does not exist on type 'Window & typeof globalThis'.
    !!window.__INITIAL_STATE__.apolloInitialState;

  if (shouldHydrate) {
    // @ts-expect-error [MC-2850] - TS2339 - Property '__INITIAL_STATE__' does not exist on type 'Window & typeof globalThis'.
    const cache = window.__INITIAL_STATE__.apolloInitialState;
    return createApolloCache(cache);
  }

  return createApolloCache();
};

const getReduxStore = (bootstrapConfig: BootstrapConfig) => {
  /* TODO: MC-2308
   * Hydrate redux store after figuring out serialization/deserialization of Immutable objects.
   */

  // const shouldHydrate =
  //   typeof window.__INITIAL_STATE__ !== 'undefined' && !!window.__INITIAL_STATE__.reduxInitialState;

  // if (shouldHydrate) {
  //   const initialState = window.__INITIAL_STATE__.reduxInitialState;
  //   return createStore({ initialState });
  // }

  const reduxStore = createStore();
  initializeStore(reduxStore, { bootstrapConfig });

  return reduxStore;
};

export type BrowserRenderContext = {
  bootstrapConfig: BootstrapConfig;
  apolloClient: ApolloGraphQLGatewayClient;
  reduxStore: Store;
  browserHistory: BrowserHistory;
};

export const createBrowserRenderContext = (
  bootstrapConfig: BootstrapConfig,
  statsigClient: StatsigClientType
): BrowserRenderContext => {
  const browserHistory = createBrowserHistory();

  const reduxStore = getReduxStore(bootstrapConfig);

  const apolloCache = getApolloCache();

  const isDLAEnabled = true;

  const apolloClient = createApolloClient({
    uri: bootstrapConfig.graphQLGatewayUrl,
    name: APOLLO_CLIENT_NAME,
    version: APOLLO_CLIENT_VERSION,
    cache: apolloCache,
    ssrMode: false,
    typeDefs: ClientSchema,
    gqlOrchestratorLinkOptions: {
      isEnabled: isDLAEnabled,
      config: orchestrationMpacConfig({
        statsigClient,
        mpacBaseUrl: bootstrapConfig.mpacBaseUrl,
        macBaseUrl: bootstrapConfig.macBaseUrl,
        wacBaseUrl: bootstrapConfig.wacBaseUrl,
        stargateBaseUrl: bootstrapConfig.stargateBaseUrl,
        featureFlags: bootstrapConfig.featureFlags,
        algoliaConfig: bootstrapConfig.appConfig.commonConfig.algoliaConfig,
      }),
      middlewares: [
        new CacheMiddleware(),
        new TwoStepAuthTimedOutMiddleware(),
        new BlockedPartnerAccessDetectionMiddleware(),
      ],
    },
  });

  return { reduxStore, bootstrapConfig, apolloClient, browserHistory };
};
