import React from 'react';
import { lazyForPaint, LazySuspense } from 'react-loosely-lazy';

import BaseRouteHandler from '../../Routes/components/common/routeHandlers/BaseRouteHandler';

const ArchivedPluginDetailsPageCodeSplit = lazyForPaint(() =>
  import(
    /* webpackChunkName: "chunk-archived-plugin-details-page" */ './ArchivedPluginDetailsPage'
  ).then((module) => module.default)
);

const RouteHandler = () => {
  return (
    <BaseRouteHandler scrollToTopOnPathChange>
      <LazySuspense fallback={<div />}>
        <ArchivedPluginDetailsPageCodeSplit />
      </LazySuspense>
    </BaseRouteHandler>
  );
};

export default RouteHandler;
