import { ANALYTICS_SCREEN_APP_VERSION_TRANSITION, ANALYTICS_SCREEN_MANAGE_APP_VERSION_ADMIN, ANALYTICS_SCREEN_MANAGE_APP_VERSION_COMPATIBILITY, ANALYTICS_SCREEN_MANAGE_APP_VERSION_DETAILS, ANALYTICS_SCREEN_MANAGE_APP_VERSION_HIGHLIGHTS, ANALYTICS_SCREEN_MANAGE_APP_VERSION_INSTRUCTIONS, ANALYTICS_SCREEN_MANAGE_APP_VERSION_LINKS, ANALYTICS_SCREEN_MANAGE_APP_VERSION_MEDIA } from '../analytics/Sources';
export const manageAppVersionBaseRoute = '/manage/apps/:appId(\\d+)/versions/:buildNumber(\\d+)';
export const appVersionTransitionRoute = {
  name: ANALYTICS_SCREEN_APP_VERSION_TRANSITION,
  path: "".concat(manageAppVersionBaseRoute, "/transition"),
  isNestedRoute: false
};
export const appVersionDetails = {
  name: ANALYTICS_SCREEN_MANAGE_APP_VERSION_DETAILS,
  path: "".concat(manageAppVersionBaseRoute, "/details"),
  isNestedRoute: false
};
export const appVersionHighlights = {
  name: ANALYTICS_SCREEN_MANAGE_APP_VERSION_HIGHLIGHTS,
  path: "".concat(manageAppVersionBaseRoute, "/highlights"),
  isNestedRoute: false
};
export const appVersionMedia = {
  name: ANALYTICS_SCREEN_MANAGE_APP_VERSION_MEDIA,
  path: "".concat(manageAppVersionBaseRoute, "/media"),
  isNestedRoute: false
};
export const appVersionInstructions = {
  name: ANALYTICS_SCREEN_MANAGE_APP_VERSION_INSTRUCTIONS,
  path: "".concat(manageAppVersionBaseRoute, "/installation"),
  isNestedRoute: false
};
export const appVersionCompatibility = {
  name: ANALYTICS_SCREEN_MANAGE_APP_VERSION_COMPATIBILITY,
  path: "".concat(manageAppVersionBaseRoute, "/compatibility"),
  isNestedRoute: false
};
export const appVersionLinks = {
  name: ANALYTICS_SCREEN_MANAGE_APP_VERSION_LINKS,
  path: "".concat(manageAppVersionBaseRoute, "/links"),
  isNestedRoute: false
};
export const appVersionAdmin = {
  name: ANALYTICS_SCREEN_MANAGE_APP_VERSION_ADMIN,
  path: "".concat(manageAppVersionBaseRoute, "/admin"),
  isNestedRoute: false
};
export const allManageAppVersionRoutes = [appVersionTransitionRoute, appVersionDetails, appVersionHighlights, appVersionMedia, appVersionInstructions, appVersionCompatibility, appVersionLinks, appVersionAdmin];