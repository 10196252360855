import { AnalyticsListener as AKAnalyticsListener } from '@atlaskit/analytics-next';
import { useStatsig } from '@atlassian/mpac-feature-flags';
import * as React from 'react';

import {
  AllowedChannels,
  sendAddonDiscoveryEvent,
  sendMarketplaceEvent,
} from './AnalyticsListener.utils';

import type { ReactNode } from 'react';

type AnalyticsListenerProps = {
  children: ReactNode;
};

const AnalyticsListener = (props: AnalyticsListenerProps) => {
  const { getEvaluatedFlags, getEvaluatedExperiments } = useStatsig();
  const flags = React.useMemo(() => getEvaluatedFlags(), [getEvaluatedFlags]);
  const experiments = React.useMemo(() => getEvaluatedExperiments(), [getEvaluatedExperiments]);

  const onEvent = (event, channel) => {
    if (channel === AllowedChannels.AddonDiscovery) {
      sendAddonDiscoveryEvent({ event, flags, experiments });
    } else if (channel === AllowedChannels.Marketplace) {
      sendMarketplaceEvent({ event, flags, experiments });
    }
  };

  return (
    <AKAnalyticsListener channel="*" onEvent={onEvent}>
      {props.children}
    </AKAnalyticsListener>
  );
};

export default AnalyticsListener;
