import Telemetry, { initTelemetry, Observers } from '@atlassian/mpac-telemetry';
import {
  createLogBackend,
  createMetalBackend,
  createPerformanceBackend,
  createReactUFOBackend,
  createSentryBackend,
  initUFO,
} from '@atlassian/mpac-telemetry/backends';
import { DOMUtils } from '@atlassian/mpac-utils';
import { getCLS, getFCP, getFID, getLCP, getTTFB } from 'web-vitals';

import type { BootstrapConfig } from '../../application-config/types/BootstrapConfig';
import type { StatsigClientType } from '@atlassian/mpac-feature-flags';

const { getNetworkRequestTimes, waitForTimeToInteractive, PageVisibilityObserver } = Observers;

// Start listening for page visibility change so we can
// differentiate foreground events from background events later
PageVisibilityObserver.start();

function initAMKTTelemetry(
  bootstrapConfig: BootstrapConfig,
  amktFrontendVersion: string,
  statsigClient: StatsigClientType
) {
  const { enableNlpSearch } = statsigClient || {};
  const metalBackend = createMetalBackend({
    env: bootstrapConfig.metal.env,
    id: bootstrapConfig.metal.id,
    version: amktFrontendVersion,
    synthetic: DOMUtils.isSynthetic(),
    subProduct: 'aether',
  });

  const sentryBackend = createSentryBackend();
  const logBackend = createLogBackend({ enabled: false });
  const performanceBackend = createPerformanceBackend({
    featureFlags: bootstrapConfig.featureFlags,
    analyticsClient: bootstrapConfig.analyticsClient,
    version: amktFrontendVersion,
  });
  const reactUFOBackend = createReactUFOBackend();
  const backends = [metalBackend, sentryBackend, logBackend, reactUFOBackend];
  const isBot = DOMUtils.isCrawler(window.navigator.userAgent) || DOMUtils.isSynthetic();
  if (!isBot) {
    backends.push(performanceBackend);
  }

  initTelemetry(backends);
  initUFO({
    analyticsClient: bootstrapConfig.analyticsClient,
    featureFlags: {
      enableNlpSearch: enableNlpSearch(),
    },
  });
}

export default (
  amktContainer: HTMLElement | null | undefined,
  bootstrapConfig: BootstrapConfig,
  amktFrontendVersion: string,
  statsigClient: StatsigClientType
) => {
  if (!amktContainer) {
    console.error('Did not find the root container, complete telemetry will not be sent');
    return;
  }

  initAMKTTelemetry(bootstrapConfig, amktFrontendVersion, statsigClient);

  getFCP(({ value }) => Telemetry.timeToFirstPaint(value));
  getLCP(({ value }) => Telemetry.timeToLargestContentfulPaint(value));
  getTTFB(({ value }) => Telemetry.timeToFirstByte(value));
  getFID(({ value }) => Telemetry.firstInputDelay(value));

  // Measured only when page is hidden / unloaded
  getCLS(({ value }) => Telemetry.cumulativeLayoutShift(value));

  if (
    'Promise' in window &&
    'PerformanceObserver' in window &&
    'PerformanceLongTaskTiming' in window &&
    'PerformancePaintTiming' in window &&
    amktContainer
  ) {
    waitForTimeToInteractive(amktContainer)
      .then((tti) => {
        Telemetry.timeToInteractive(tti);

        const requestTransferTimes = getNetworkRequestTimes(
          [/marketplace.*atlassian.com/, /:\/\/localhost/, /algolianet.com/],
          []
        );

        requestTransferTimes.forEach((requestGroup) =>
          Telemetry.resourceDownloadTime(requestGroup.type, requestGroup.duration)
        );
      })
      .catch(console.error.bind(console));
  }
};
