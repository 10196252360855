import { fromJS } from 'immutable';
import thunk from 'redux-thunk';

import { receiveAppConfig } from '../actions/App';
import AppConfig from '../records/AppConfig';
import { resolveCommonConfig } from '../records/CommonConfig';
import { resolveGeneralLinks } from '../records/GeneralLinks';
import { resolveVendorDetailsConfig } from '../records/VendorDetailsConfig';
import { createReduxStore } from './createReduxStore';

import type { BootstrapConfig } from '../../application-config/types/BootstrapConfig';
import type { Store } from './types';

type CreateStoreOptions = {
  initialState?: any;
};

/**
 * Create the base redux store and apply the middleware used in the app.
 * This setup is done before we have bootstrap data available.
 */
export const createStore = ({ initialState }: CreateStoreOptions = {}) =>
  createReduxStore({ middleware: [thunk], initialState });

type InitStoreOptions = {
  bootstrapConfig: BootstrapConfig;
};

/**
 * During the bootstrap phase, populate the app config into the redux store for the
 * rest of the app to use.
 *
 * NOTE: This initialization is not required if the store was created with server
 * initial state already passed into it.
 */
export const initializeStore = (store: Store, { bootstrapConfig }: InitStoreOptions) => {
  const appConfig = fromJS(bootstrapConfig.appConfig);
  store.dispatch(
    receiveAppConfig({
      appConfig: new AppConfig({
        commonConfig: resolveCommonConfig({ appConfig }),
        generalLinks: resolveGeneralLinks({ appConfig }),
        vendorDetails: resolveVendorDetailsConfig({ appConfig }),
      }),
    })
  );
};
