import { combineReducers } from 'redux';

import subscribeMailingListReducer from '../components/common/SubscribeMailingListDialog/reducer';
import appReducer from './App';
import commonConfigReducer from './CommonConfig';
import generalLinksReducer from './GeneralLinks';
import userReducer from './UserData';

export default combineReducers({
  app: appReducer,
  generalLinks: generalLinksReducer,
  commonConfig: commonConfigReducer,
  user: userReducer,
  subscribeMailingList: subscribeMailingListReducer,
});
