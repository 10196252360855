import { useQuery } from '@apollo/client';
import { SpinnerMask } from '@atlassian/amkt-common';
import { NotFound } from '@atlassian/mpac-primitives';
import { useParams } from '@atlassian/mpac-ui';
import invariant from 'invariant';

import SEOFriendlyRedirect from '../../../../common/components/SEOFriendlyRedirect';

import type {
  GetMarketplacePluginByAppKeyQuery,
  GetMarketplacePluginByAppKeyQueryVariables,
} from '../../Routes/components/AppListingRoutes/components/PluginsRedirectRoute/queries.gql.types';

import { GetMarketplacePluginByAppKey } from '../../Routes/components/AppListingRoutes/components/PluginsRedirectRoute/queries.graphql';

export const VersionRedirectRoute = () => {
  const params = useParams();
  const { appKey, buildNumber } = params;

  invariant(appKey, `parameter 'appKey' is required`);
  invariant(buildNumber, `parameter 'buildNumber' is required`);

  const { data, loading } = useQuery<
    GetMarketplacePluginByAppKeyQuery,
    GetMarketplacePluginByAppKeyQueryVariables
  >(GetMarketplacePluginByAppKey, {
    variables: { appKey },
  });

  if (loading) {
    return <SpinnerMask active delay={750} testId="version-redirect" />;
  }

  if (data?.marketplaceAppByKey?.appId) {
    const { appId, slug } = data.marketplaceAppByKey;
    return (
      <SEOFriendlyRedirect
        to={{ pathname: `/apps/${appId}/${slug}/version-history`, hash: `#b${buildNumber}` }}
        statusCode={301}
      />
    );
  }
  return <NotFound />;
};
