import type { ApplicationConfig, InitialConfig } from '../application-config/types/BootstrapConfig';

type GetAppConfigArgs = {
  applicationConfig: ApplicationConfig;
  initialConfig: InitialConfig;
};

const getFilesLink = (baseUrl: string) =>
  `${baseUrl}/files/{fileName}?fileType=image&mode=full-fit`;

const getGeneralLinks = ({ applicationConfig }: GetAppConfigArgs) => {
  const { mpacCdnBaseUrl, preferencesBaseUrl, mpacBaseUrl } = applicationConfig;

  return {
    addonListingWithHosting: {
      href: '/apps/{pluginId}/{appName}{?hosting,tab}',
      templated: true,
    },
    files: {
      href: getFilesLink(mpacCdnBaseUrl),
      templated: true,
    },
    getMailingListLocale: {
      href: `${preferencesBaseUrl}/rest/forms/locale`,
      templated: true,
    },
    subscribeToMailingList: {
      href: `${mpacBaseUrl}/rest/internal/newsletters/subscriptions`,
      templated: true,
    },
    subscribeToMailingListFailed: {
      href: `${mpacBaseUrl}/rest/internal/users/subscription-failed{?emailId,mailingListId}`,
      templated: true,
    },
    currentUser: {
      href: `${mpacBaseUrl}/rest/internal/users/current`,
    },
  };
};

const getCommonConfig = ({ applicationConfig, initialConfig }: GetAppConfigArgs) => {
  const { algolia, analytics } = applicationConfig;
  const { frontendBootstrap } = initialConfig;
  return {
    algoliaConfig: {
      applicationId: algolia.applicationId,
      apiKey: algolia.apiKey,
      environment: algolia.environment,
    },
    contentfulConfig: {
      authToken: frontendBootstrap.isAdmin
        ? '95c3b871aa1b5e5a103917933a44ff649850e1c94de1cfbb2cf0e9f8ba32704c'
        : '34f9a2bf6ab2d9c35b7f312bd5b559c69e4386c11d441c5660add1df7821bb31',
      spaceId: 'xi2je6di2mcr',
      previewMode: !!frontendBootstrap.isAdmin,
    },
    googleAnalyticsId: analytics.googleAnalyticsId,
    googleAnalyticsGa4Id: analytics.googleAnalyticsGa4Id,
    environment: algolia.environment,
  };
};

const getVendorDetailsConfig = ({ applicationConfig }: GetAppConfigArgs) => {
  const { mpacBaseUrl } = applicationConfig;
  return {
    metricsKeys: {
      vendorDetails: 'http.vendors_vendorId_vendorName.get',
    },
    links: {
      vendorDetailsPage: {
        href: '/vendors/{vendorId}/{vendorName}',
        templated: true,
      },
      vendorAddonsRest: {
        href: `${mpacBaseUrl}/rest/2/addons/vendor/{vendorId}{?application,applicationBuild,category*,cost,filter,forThisUser,hosting*,includeHidden,includePrivate,marketingLabel*,text,withVersion,storesPersonalData,offset,limit}`,
        templated: true,
      },
      vendorArchivedAddonsRest: {
        href: `${mpacBaseUrl}/rest/2/addons/archived/vendor/{vendorId}{?application,applicationBuild,category*,cost,filter,forThisUser,hosting*,includeHidden,includePrivate,marketingLabel*,text,withVersion,storesPersonalData,offset,limit}`,
        templated: true,
      },
      vendorDetailsRest: {
        href: `${mpacBaseUrl}/rest/2/vendors/{vendorId}`,
        templated: true,
      },
    },
  };
};

export const getBootstrapConfig = (
  applicationConfig: ApplicationConfig,
  initialConfig: InitialConfig
) => {
  const { analyticsClient, featureFlags } = initialConfig;

  return {
    /* Picked from application config directly */
    adminHubBaseUrl: applicationConfig.adminHubBaseUrl,
    graphQLGatewayUrl: applicationConfig.graphQLGatewayUrl,
    mpacBaseUrl: applicationConfig.mpacBaseUrl,
    mpacCdnBaseUrl: applicationConfig.mpacCdnBaseUrl,
    macBaseUrl: applicationConfig.macBaseUrl,
    wacBaseUrl: applicationConfig.wacBaseUrl,
    stargateBaseUrl: applicationConfig.stargateBaseUrl,
    atlassianCommunityBaseUrl: applicationConfig.atlassianCommunityBaseUrl,
    jiraServiceDeskEmbedBaseUrl: applicationConfig.jiraServiceDeskEmbedBaseUrl,
    identityBaseUrl: applicationConfig.identityBaseUrl,
    environment: applicationConfig.environment,
    sentryDsn: applicationConfig.sentry.dsn,
    analytics: applicationConfig.analytics,
    metal: applicationConfig.metal,
    enableIdentityHeartbeats: applicationConfig.enableIdentityHeartbeats,

    /* Additional app config derived using application config, feature flags */
    appConfig: {
      generalLinks: getGeneralLinks({ applicationConfig, initialConfig }),
      commonConfig: getCommonConfig({ applicationConfig, initialConfig }),
      vendorDetails: getVendorDetailsConfig({ applicationConfig, initialConfig }),
    },

    analyticsClient,
    featureFlags,

    feedbackCollectorApiKeys: applicationConfig.feedbackCollectorApiKeys,

    feedbackGatewayBaseUrl: applicationConfig.feedbackGatewayBaseUrl,
    launchDarklyUserKey: initialConfig.frontendBootstrap.launchDarklyUserKey,
  };
};
