import { AppListingTabs } from '@atlassian/mpac-app-listing';

import { AllHostingTypeQueryParams } from '../../../../../constants';
import AppsRoute from './AppsRoute';
import {
  PluginDetailsRedirectRoute,
  PluginsIconRedirectRoute,
  PluginsRedirectRoute,
} from './PluginsRedirectRoute';

import type { AMKTRoute } from '../../Routes.types';

const TABS_MATCH = AppListingTabs.join('|');
const HOSTING_TYPES_MATCH = AllHostingTypeQueryParams.join('|');

/**
 * Routes for app-listing, including:
 * - /apps
 * - /plugins
 */
export default (): AMKTRoute[] => [
  {
    name: 'appListingScreen',
    exact: true,
    path: '/apps/:appId(\\d+)/:appSlug([^/]+)?',
    component: AppsRoute,
    suppressPageView: true,
    showAetherNavAndFooter: true,
  },
  {
    name: 'appListingScreen',
    exact: true,
    path: `/plugins/:appKey([^/]+)/:hosting(${HOSTING_TYPES_MATCH})?/:tab(${TABS_MATCH})?`,
    suppressPageView: true,
    component: PluginsRedirectRoute,
    showAetherNavAndFooter: true,
  },
  {
    name: 'appListingScreen',
    exact: true,
    path: `/plugin/details/:appId(\\d+)`,
    suppressPageView: true,
    component: PluginDetailsRedirectRoute,
    showAetherNavAndFooter: true,
  },
  {
    name: 'appListingScreen',
    exact: true,
    path: `/plugin/details/:appKey([^/]+)`,
    suppressPageView: true,
    component: PluginsRedirectRoute,
    showAetherNavAndFooter: true,
  },
  {
    name: 'appListingScreen',
    exact: true,
    path: `/plugins/:appKey([^/]+)/icon`,
    suppressPageView: true,
    component: PluginsIconRedirectRoute,
    showAetherNavAndFooter: true,
  },
];
