import { differenceInDays, differenceInMonths, format, isFuture, isToday, isValid, parse, setISODay, sub } from 'date-fns';
import { log } from './sentry';
/**
 * Convert the day of week from the ISO day of week (Monday:1 ... Sunday:7) to:
 * - the short 3 letter abbreviation (eg. "Mon"), when `short = true`
 * - the full day name "Monday" when `short = false`
 */
export const formatDayOfWeek = (day, short) => format(setISODay(new Date(), day), short ? 'iii' : 'iiii');

/**
 * Given an array of ISO days of week (Mon: 1 to Sunday: 7), sorts them in order of Mon-Sun & returns all weekday "ranges".
 * @example toWeekdayRanges([1,2,5) => ["Mon-Tue","Fri"]
 * @example toWeekdayRanges([1,3,5]) => ["Mon","Wed","Fri"]
 * @example toWeekdayRanges([5,1,2,3,6]) => ["Mon-Wed","Fri-Sat"]
 */
export const toWeekdayRanges = (days, options) => {
  const orderedDays = [...days].sort(); // sort() mutates the array, hence making a copy

  const {
    shortDaysOfWeek = true
  } = options || {};
  const ranges = [[]];
  let currentRange = 0;

  /* This loop puts the days into ranges, example:
    days: [2,3,5,6]
    ranges: [[2,3],[5,6]]
  */
  for (let i = 0; i < orderedDays.length; i += 1) {
    ranges[currentRange].push(orderedDays[i]);
    if (orderedDays[i + 1] !== orderedDays[i] + 1 && i !== orderedDays.length - 1) {
      currentRange += 1;
      ranges[currentRange] = [];
    }
  }

  // flatten ranges of the day "orders" back into day name ranges
  // ranges: [[2,3],[5,6]]
  // result: ["Tue-Wed","Fri-Sat"]
  return ranges.map(range => {
    const startDay = formatDayOfWeek(range[0], shortDaysOfWeek);
    const endDay = formatDayOfWeek(range[range.length - 1], shortDaysOfWeek);
    return startDay === endDay ? startDay : [startDay, endDay].join('-');
  });
};

/**
 * Converts a given 24-hour time string to the corresponding 12 hour format.
 * @example to12HourFormat("17:00") => "5:00 PM"
 * @example to12HourFormat("08:00") => "8:00 AM"
 */
export const to12HourFormat = time => {
  const date = parse(time, 'HH:mm', new Date());
  return format(date, 'h:mm aa');
};

/**
 * Format the date as `MMM Do YYYY` or any other format string provided.
 * @example longDate(new Date()) => 'Feb 27th 2024'
 * @see [date-fns/format](https://date-fns.org/v2.16.1/docs/format)
 */
export const longDate = (date, formatString) => format(new Date(date), formatString || 'MMM do yyyy');

/**
 * Subtract the date by given number of days
 * @example formatDate("2022-03-22", 10) => '2022-03-12'
 * @see [date-fns/sub](https://date-fns.org/v2.16.1/docs/sub)
 */
export const subtractDateByDays = (date, days) => sub(new Date(date), {
  days
});

/**
 * Is the given date in the future?
 * @see [date-fns/sub](https://date-fns.org/v2.16.1/docs/isFuture)
 */
export const isFutureDate = date => isFuture(date);

/**
 * Is the given date in the future?
 * @see [date-fns/sub](https://date-fns.org/v2.16.1/docs/isFuture)
 * @see [date-fns/sub](https://date-fns.org/v2.16.1/docs/isToday)
 */
export const isTodayOrFutureDate = date => isToday(date) || isFuture(date);

/**
 * Format the date as `yyyy-MM-dd`.
 * @example ISOFormatHyphenatedDate(new Date()) => '2024-02-27'
 * @see [date-fns/format](https://date-fns.org/v2.16.1/docs/format)
 */
export const ISOFormatHyphenatedDate = date => format(new Date(date), 'yyyy-MM-dd');

/**
 * Returns the `differenceInDays(date, Date.now())`.
 * **Note**: Will return a _negative_ value for past dates & a positive value for future dates.
 * @example differenceInDaysToNow(new Date('2020-12-31') => -18 // if today is 2021-01-18
 * @example differenceInDaysToNow(new Date('2021-01-30') => 11 // if today is 2021-01-18
 * @see [date-fns/format](https://date-fns.org/v2.16.1/docs/differenceInDays)
 */
export const differenceInDaysToNow = date => differenceInDays(date, Date.now());

/**
 * Returns the ISO weekday number (Monday:1 ... Sunday:7) :
 * - accepts full day name
 * @example weekdayToISODayNumber('wednesday') => 3
 */
export const weekdayToISODayNumber = day => {
  const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  return days.findIndex(dayName => dayName === day.toLocaleLowerCase()) + 1;
};

/**
 * Returns UTC time in 24 hours format
 * example: 1671178355 -> 08:12
 */
export function getUtcTime(unixTime) {
  const date = new Date(unixTime);
  const hours = date.getUTCHours();
  const hour = (hours % 12).toString().padStart(2, '0');
  const meridiem = hours < 12 ? 'a.m.' : 'p.m.';
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');
  return "".concat(hour, ":").concat(minutes, " ").concat(meridiem);
}

/**
 * Returns UTC date in day month, year format
 * example: 1671178355 -> 17th December, 2022
 */
export function getUtcLongDate(unixTime) {
  const date = new Date(unixTime);
  const day = date.getUTCDate();
  const month = date.toLocaleString('en-US', {
    month: 'long'
  });
  const year = date.getUTCFullYear();
  return "".concat(month, " ").concat(day, ", ").concat(year);
}

/**
 * Returns day of the week
 * example: 1671178355 -> Saturday
 */
export function getDay(unixTime) {
  const date = new Date(unixTime);
  const dayIndex = date.getDay();
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  // Get the day name using the day index
  const dayName = days[dayIndex];
  return dayName;
}

/**
 * Returns the difference between two dates in a formatted string.
 * If the difference is more than a month, it returns the difference in months.
 * Otherwise, it returns the difference in days.
 * @example getDifferenceInDaysOrMonths(new Date('2023-01-01'), new Date('2021-02-01')) => '1 month'
 * @example getDifferenceInDaysOrMonths(new Date('2023-01-01'), new Date('2021-01-15')) => '14 days'
 * @example getDifferenceInDaysOrMonths(new Date('2023-01-01'), new Date('2021-01-15'), { pluralizeUnits: false, countUnitSeparator: '-'}) => '14-day'
 */
export function differenceInDaysOrMonths(endDate, startDate) {
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
    pluralizeUnits: true,
    countUnitSeparator: ' '
  };
  const monthsDiff = differenceInMonths(endDate, startDate);
  const daysDiff = differenceInDays(endDate, startDate);
  const pluralize = (unit, count) => count > 1 ? unit + 's' : unit;
  if (monthsDiff > 0) {
    return "".concat(monthsDiff).concat(options.countUnitSeparator).concat(options.pluralizeUnits ? pluralize('month', monthsDiff) : 'month');
  } else {
    return "".concat(daysDiff).concat(options.countUnitSeparator).concat(options.pluralizeUnits ? pluralize('day', daysDiff) : 'day');
  }
}

/**
 * Takes date string and context as parameters.
 * Converts the date string of format "yyy-MM-dd" to a Date object and returns it .
 * If Date string is invalid then logs an error with context as location and returns a Date object for current time
 */
export const parseDateString = function parseDateString(dateString) {
  let context = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'marketplace';
  const date = parse(dateString, 'yyyy-MM-dd', new Date());
  if (!isValid(date)) {
    log.error(context, 'Error in creating Date object');
    return new Date();
  }
  return date;
};

/**
 * Create a date range from the yesterday's date to the given number of days ago.
 * @param daysAgo
 * @returns { startDate: string, endDate: string }
 */
export const calculateDateRange = daysAgo => {
  const dateNow = Date.now();
  return {
    startDate: ISOFormatHyphenatedDate(subtractDateByDays(dateNow, daysAgo)),
    endDate: ISOFormatHyphenatedDate(subtractDateByDays(dateNow, 1))
  };
};
export const formatDate = function formatDate(dateString, formatString) {
  let context = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'marketplace';
  const date = Date.parse(dateString);
  if (!Number.isNaN(date)) {
    const dateObject = parseDateString(dateString, context);
    return format(dateObject, formatString);
  } else {
    return dateString;
  }
};
export const formatDateObject = (date, formatString) => {
  return format(date, formatString);
};
export const dateToISODayNumber = date => {
  const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  const dayName = format(date, 'iiii').toLowerCase();
  return days.findIndex(day => day === dayName) + 1;
};