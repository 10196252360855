import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { FeatureGateEnvironment, PerimeterType } from '@atlaskit/feature-gate-js-client';
import FeatureGateClient from '@atlaskit/feature-gate-js-client/client';
export { FeatureGateEnvironment as StatsigEnvironment };
export class StatsigClient {
  constructor() {
    var _this = this;
    _defineProperty(this, "marketplaceClientInstance", undefined);
    _defineProperty(this, "isStatsigInitialized", false);
    _defineProperty(this, "evaluatedFlags", {});
    _defineProperty(this, "evaluatedExperiments", {});
    _defineProperty(this, "source", undefined);
    _defineProperty(this, "customAttributes", void 0);
    _defineProperty(this, "clientOptions", void 0);
    _defineProperty(this, "identifers", void 0);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _defineProperty(this, "overrides", {});
    _defineProperty(this, "getExperimentValue", function (experimentName, propertyName) {
      var _this$marketplaceClie;
      let defaultValue = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      let options = arguments.length > 3 ? arguments[3] : undefined;
      if (!_this.isStatsigInitialized || !_this.marketplaceClientInstance) {
        console.warn('Statsig client not initialized.');
        return defaultValue;
      }
      if (_this.overrides[experimentName] !== undefined && _this.overrides[experimentName][propertyName] !== undefined) {
        return _this.overrides[experimentName][propertyName];
      }
      const evaluatedValue = (_this$marketplaceClie = _this.marketplaceClientInstance.getExperimentValue(experimentName, propertyName, defaultValue, options)) !== null && _this$marketplaceClie !== void 0 ? _this$marketplaceClie : defaultValue;
      _this.evaluatedExperiments = _objectSpread(_objectSpread({}, _this.evaluatedExperiments), {}, {
        [experimentName]: _objectSpread(_objectSpread({}, _this.evaluatedExperiments[experimentName]), {}, {
          [propertyName]: evaluatedValue
        })
      });
      return evaluatedValue;
    });
    _defineProperty(this, "checkGate", function (gateName) {
      var _this$marketplaceClie2;
      let defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      if (!_this.isStatsigInitialized || !_this.marketplaceClientInstance) {
        console.warn('Statsig client not initialized.');
        return defaultValue;
      }
      if (_this.overrides[gateName] !== undefined) {
        return Boolean(_this.overrides[gateName]);
      }
      const evaluatedValue = (_this$marketplaceClie2 = _this.marketplaceClientInstance.checkGate(gateName)) !== null && _this$marketplaceClie2 !== void 0 ? _this$marketplaceClie2 : defaultValue;
      _this.evaluatedFlags[gateName] = evaluatedValue;
      return evaluatedValue;
    });
    _defineProperty(this, "getDynamicConfig", (configName, defaultValue) => {
      var _configValue$value;
      if (!this.isStatsigInitialized || !this.marketplaceClientInstance) {
        console.warn('Statsig client not initialized.');
        return defaultValue;
      }
      const configValue = this.marketplaceClientInstance.getExperiment(configName);
      return (configValue === null || configValue === void 0 ? void 0 : (_configValue$value = configValue.value) === null || _configValue$value === void 0 ? void 0 : _configValue$value.value) || defaultValue;
    });
    _defineProperty(this, "initializeStatsigClientWithValues", async (_ref, source) => {
      let {
        marketplaceAnonymousId,
        environment,
        targetApp,
        clientSdkKey,
        experimentValues,
        customAttributes
      } = _ref;
      this.source = source;
      const featureGates = new FeatureGateClient();
      this.marketplaceClientInstance = featureGates;
      await this.marketplaceClientInstance.initializeFromValues({
        environment,
        targetApp,
        sdkKey: clientSdkKey
      }, {
        marketplaceAnonymousId
      }, customAttributes, experimentValues);
      this.isStatsigInitialized = true;
    });
    _defineProperty(this, "updateIdentifiers", async overrideIdentifiers => {
      if (!this.marketplaceClientInstance || !this.isStatsigInitialized || !this.clientOptions) {
        console.warn('Statsig client not initialized.');
        return;
      }
      const {
        environment,
        apiKey,
        targetApp
      } = this.clientOptions;
      this.identifers = _objectSpread(_objectSpread({}, this.identifers), overrideIdentifiers);
      try {
        await this.marketplaceClientInstance.updateUser({
          environment,
          apiKey,
          targetApp,
          // this is optional in FeatureGates.initialize but required for FeatureGates.updateUser
          // COMMERCIAL is the default value for perimeter if we don't provide one
          perimeter: PerimeterType.COMMERCIAL
        }, this.identifers, this.customAttributes);
      } catch (err) {
        console.error('Failed to update identifiers.', err);
      }
    });
    _defineProperty(this, "setOverride", overrideValues => {
      this.overrides = overrideValues;
    });
    _defineProperty(this, "getEvaluatedFlags", () => {
      return this.evaluatedFlags;
    });
    _defineProperty(this, "getEvaluatedExperiments", () => {
      return this.evaluatedExperiments;
    });
    // ---------------------- Experiments EMCEE-------------------------
    /**
     * discussion: https://atlassian.enterprise.slack.com/archives/C04PR2YE4UC/p1725317404567309?thread_ts=1725277152.022099&channel=C04PR2YE4UC&message_ts=1725317404.567309
     * suggests that the exposures from the other target apps will cause issues, and recommends either suppressing the experiment or adding an in-code check
     **/
    // ---------------------- Experiments MPAC-------------------------
    _defineProperty(this, "enableNlpSearch", function () {
      let {
        fireExperimentExposure = false
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      if (_this.source === 'PRODUCT') {
        return false;
      }
      if (_this.source === 'MPAC') {
        return _this.getExperimentValue('enable_mpac_nlp_search_experiment', 'isExperienceEnabled', false, {
          fireExperimentExposure
        });
      }
      return false;
    });
    _defineProperty(this, "enableSponsoredAboveExperiment", () => {
      if (this.source === 'PRODUCT') {
        return false;
      }
      if (this.source === 'MPAC') {
        const position = this.getExperimentValue('enable_sponsored_listing_mpac', 'position', 'none');
        return position === 'above';
      }
      return false;
    });
    _defineProperty(this, "enableSponsoredBelowExperiment", () => {
      if (this.source === 'PRODUCT') {
        return false;
      }
      if (this.source === 'MPAC') {
        const position = this.getExperimentValue('enable_sponsored_listing_mpac', 'position', 'none');
        return position === 'below';
      }
      return false;
    });
    _defineProperty(this, "enableOneClickAppInstall", () => {
      if (this.source === 'MPAC') {
        return this.getExperimentValue('enable_one_click_install_mpac', 'isExperienceEnabled', false);
      }
      if (this.source === 'PRODUCT') {
        return this.checkGate('enable_emcee_one_click_install');
      }
      return false;
    });
    // ---------------------- Feature Flags ----------------------
    _defineProperty(this, "enableStaffPickOnAppListing", () => {
      return this.checkGate('enable_staff_pick_on_app_listing');
    });
    _defineProperty(this, "enableTwoClickAppInstall", () => {
      return this.checkGate('enable_two_step_app_installation_marketplace');
    });
    _defineProperty(this, "showCompassApps", () => {
      return this.checkGate('show_compass_apps');
    });
    _defineProperty(this, "showNewJiraSoftwareLabel", () => {
      return this.checkGate('show_new_jira_software_label');
    });
    _defineProperty(this, "showChangemakersCollectionTile", () => {
      return this.checkGate('show_changemakers_collection_tile');
    });
    _defineProperty(this, "enableCurrentUserApiViaStoreBff", () => {
      return this.checkGate('enable_current_user_api_via_store_bff');
    });
    _defineProperty(this, "enableGeoIPFromStoreBff", () => {
      return this.checkGate('enable_geoip_from_store_bff', true);
    });
    _defineProperty(this, "enableTrustCenterSection", () => {
      return this.checkGate('enable_trustcenter_section');
    });
    _defineProperty(this, "enableTrustCenterNewLozenge", () => {
      return this.checkGate('enable_trust_center_new_lozenge');
    });
    _defineProperty(this, "enableMediaFromProductListing", () => {
      return this.checkGate('enable_media_from_product_listing');
    });
    _defineProperty(this, "enableAppBundles", () => {
      return this.checkGate('enable_app_bundles');
    });
    _defineProperty(this, "enableTeamsWithMakerIntegration", () => {
      return this.checkGate('partner_teams_tab_maker_integration');
    });
    _defineProperty(this, "displayAuditTabInManagePartnerPage", () => {
      return this.checkGate('display_audit_tab_in_manage_partner_page');
    });
    _defineProperty(this, "enableAlgoliaCallOnAppListingAndDialogs", () => {
      return this.checkGate('enable_algolia_call_on_applising_and_dialogs');
    });
    _defineProperty(this, "enableEditionsGA", () => {
      return this.checkGate('enable_partner_app_edition_tab');
    });
    _defineProperty(this, "enableCapabilitySets", () => {
      return this.getDynamicConfig('enablecapabilitysets', []);
    });
    _defineProperty(this, "enableScalingLogicOnUI", () => {
      return this.checkGate('enable_scaling_logic_on_ui');
    });
    _defineProperty(this, "enablePartnerUserApiViaConsoleBffGql", () => {
      return this.checkGate('enable_partner_user_api_via_console_bff_gql');
    });
    _defineProperty(this, "enablePartnerPaymentsViaConsoleBff", () => {
      return this.checkGate('enable_partner_payments_via_console_bff');
    });
    _defineProperty(this, "partnersNavigationRefresh", () => {
      return this.checkGate('partners_navigation_refresh');
    });
    _defineProperty(this, "enableForgeRemoteDataResidencyChanges", () => this.checkGate('forge_remote_data_residency_changes'));
    _defineProperty(this, "enableRunsOnAtlassian", () => {
      if (this.source === 'PRODUCT') {
        return this.checkGate('enable_runs_on_atlassian_emcee');
      }
      if (this.source === 'MPAC') {
        return this.checkGate('runs_on_atlassian_marketplace');
      }
      return false;
    });
    _defineProperty(this, "enableAppReviewSummaryFromStoreBff", () => {
      if (this.source === 'PRODUCT') {
        return this.checkGate('get_app_review_summary_for_emcee_from_store_bff');
      }
      if (this.source === 'MPAC') {
        return this.checkGate('get_app_review_summary_for_mpac_from_store_bff');
      }
      return false;
    });
    _defineProperty(this, "showUpdatedPartnerTierMessage", () => this.checkGate('partner_tier_messaging_update'));
    _defineProperty(this, "show150KTierForDCAnnouncement", () => this.checkGate('show_150k_tier_for_dc_announcement'));
    // ---------------------- Permanent Feature Flags ----------------------
    _defineProperty(this, "enableDcMarketplaceDownloadSignature", () => {
      return this.checkGate('enable-dc-marketplace-download-signature');
    });
    _defineProperty(this, "showRevampedCloudPricingTab", () => this.checkGate('show_revamped_cloud_pricing_tab'));
    _defineProperty(this, "enable150KTierSupport", () => this.checkGate('enable_150k_tier_support'));
    _defineProperty(this, "fetchHostProductPricingFromConsoleBff", () => this.checkGate('fetch_parent_product_pricing_from_console_bff'));
    _defineProperty(this, "enableCustomer150kTierSupport", () => this.checkGate('enable_customer_150k_tiers'));
    _defineProperty(this, "showEditionsAnnouncementAlert", () => this.checkGate('show_editions_announcement_alert'));
    _defineProperty(this, "enableSelfServeAppEditions", () => this.checkGate('enable_self_serve_app_editions_view'));
    _defineProperty(this, "enableMILsupport", () => this.checkGate('enable_mil_support'));
    // https://switcheroo.atlassian.com/ui/gates/47da0e52-7689-4b69-9f5d-435dc1f32cd0/key/enable_create_urm_req_for_maketplace_app_req
    _defineProperty(this, "enableCreateUrmRequestForMarketplaceAppRequest", () => this.checkGate('enable_create_urm_req_for_maketplace_app_req'));
    _defineProperty(this, "enableMILReportingSupport", () => this.checkGate('enable_mil_reporting_support'));
    _defineProperty(this, "enableAppEditionM3ReportingSupport", () => this.checkGate('enable_app_edition_m3_reporting_support'));
    _defineProperty(this, "enableMpacWebCoherence", () => this.checkGate('enable_mpac_web_coherence'));
    _defineProperty(this, "enableMILCustomerSupport", () => this.checkGate('enable_mil_customer_support'));
    _defineProperty(this, "enableV3SearchIndex", () => this.checkGate('enable_v3_search_index'));
    _defineProperty(this, "allowRequestApp", () => this.checkGate('request-app'));
  }
  // IMPORTANT: Calling this method will completely re-initialize the client with a new set of flags. You will need to re-render the entire page after this completes to ensure everything picks up the new flag values. You should avoid using this frequently as it has implications on the user experience.
  async initializeStatsigClient(_ref2) {
    let {
      clientKey,
      environment,
      targetApp,
      marketplaceAnonymousId,
      tenantId,
      atlassianAccountId
    } = _ref2;
    let customAttributes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let source = arguments.length > 2 ? arguments[2] : undefined;
    this.source = source;
    const clientOptions = {
      apiKey: clientKey,
      environment,
      targetApp,
      fetchTimeoutMs: 5000
    };
    const identifiers = {
      marketplaceAnonymousId,
      tenantId,
      atlassianAccountId
    };
    try {
      const featureGates = new FeatureGateClient();
      this.marketplaceClientInstance = featureGates;
      await this.marketplaceClientInstance.initialize(clientOptions, identifiers, customAttributes);
      this.isStatsigInitialized = true;
      this.identifers = identifiers;
      this.clientOptions = clientOptions;
      this.customAttributes = customAttributes;
    } catch (err) {
      console.error('Failed to initialize FeatureGates client.', err);
    }
  }
}
export default StatsigClient;