import { Record } from 'immutable';

import CommonConfig from './CommonConfig';
import GeneralLinks from './GeneralLinks';
import VendorDetailsConfig from './VendorDetailsConfig';

export default class AppConfig extends Record({
  commonConfig: new CommonConfig(),
  generalLinks: new GeneralLinks(),
  vendorDetails: new VendorDetailsConfig(),
}) {}
