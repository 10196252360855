import { AlgoliaEnvironment } from '@atlassian/mpac-types';
const searchIndicesV2 = {
  [AlgoliaEnvironment.DEV]: {
    indexName: 'marketplace_plugins_dev_v2'
  },
  [AlgoliaEnvironment.STG]: {
    indexName: 'marketplace_plugins_stg_v2'
  },
  [AlgoliaEnvironment.PROD]: {
    indexName: 'marketplace_plugins_prod_v2'
  }
};
const searchIndicesV3 = {
  [AlgoliaEnvironment.DEV]: {
    indexName: 'marketplace_plugins_stg_v3'
  },
  [AlgoliaEnvironment.STG]: {
    indexName: 'marketplace_plugins_stg_v3'
  },
  [AlgoliaEnvironment.PROD]: {
    indexName: 'marketplace_plugins_prod_v3'
  }
};
export { searchIndicesV2, searchIndicesV3 };