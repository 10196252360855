import { media } from '@atlaskit/primitives/responsive';
import { token } from '@atlaskit/tokens';
import { BodyNormal, FluidContainer, SubtleLink } from '@atlassian/mpac-primitives';
import { ellipsisCompiled } from '@atlassian/mpac-ui/theme';
import { keyframes, styled } from '@compiled/react';

export const Background = styled.div({
  position: 'relative',
  background: token('color.background.accent.gray.subtlest'),
  zIndex: 200,
});

export const Container = styled(FluidContainer)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: 'auto',
  height: '56px',
  [media.above.sm]: {
    height: '64px',
  },
});

export const LeftContent = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

export const Label = styled(BodyNormal)({
  margin: `0 0 0 ${token('space.100')}`,
  ...ellipsisCompiled,
});

export const PartnerName = styled(Label)({
  [media.above.md]: {
    marginRight: token('space.100'),
  },
});

export const DesktopLink = styled(SubtleLink)({
  margin: `0 0 0 ${token('space.150')}`,
  [media.above.md]: {
    margin: `0 0 0 ${token('space.200')}`,
  },
  padding: `${token('space.050')} ${token('space.075')}`,
  lineHeight: '2rem',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
});

export const DropdownTriggerLink = styled.button<{ isOpen: boolean }>({
  fontFamily: token('font.family.brand.body'),
  fontSize: '1rem',
  lineHeight: '2rem',
  fontWeight: '600',
  color: token('color.text'),
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  borderRadius: '3px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: 'none',
  cursor: 'pointer',
  padding: `${token('space.050')} ${token('space.075')}`,
  '& path': {
    fill: token('color.icon'),
  },
  '&:active': {
    backgroundColor: token('color.background.neutral.pressed'),
  },
  backgroundColor: ({ isOpen }) =>
    isOpen ? token('color.background.neutral.pressed') : token('color.background.neutral.subtle'),
});

export const DropdownTriggerIcon = styled.div({
  marginLeft: token('space.050'),
  display: 'flex',
  alignItems: 'center',
});

const heightReveal = keyframes({
  from: {
    maxHeight: 0,
  },
  to: {
    maxHeight: '4rem',
  },
});

export const HeightAnimationContainer = styled.div({
  overflow: 'hidden',
  position: 'relative',
  animation: `${heightReveal} 0.5s ease-in-out`,
});
