// Analytics Actions
import { SCREEN_EVENT_TYPE, TRACK_EVENT_TYPE, UI_EVENT_TYPE } from './types/AnalyticsEventType';

// Event Container

export const makeEventContainer = v => v;
export const EVENT_CONTAINER_CLOUD_FORTIFIED_INFO_MODAL = makeEventContainer('cloudFortifiedInfoModal');
export const EVENT_CONTAINER_CLOUD_FORTIFIED_TOOLTIP = makeEventContainer('cloudFortifiedTooltip');
export const EVENT_CONTAINER_RUNS_ON_ATLASSIAN_TOOLTIP = makeEventContainer('runsOnAtlassianTooltip');
export const EVENT_CONTAINER_CERTIFICATION_SECTION = makeEventContainer('certificationSection');
export const EVENT_CONTAINER_HOME_SCREEN = makeEventContainer('homeScreen');
export const EVENT_CONTAINER_COLLECTIONS_SCREEN = makeEventContainer('collectionsScreen');
export const EVENT_CONTAINER_APPLISTING_SCREEN = makeEventContainer('appListingScreen');
export const EVENT_CONTAINER_SUPPORT_APPLISTING_TAB = makeEventContainer('supportAppListingTab');
export const ANALYTICS_EVENT_CONTAINER_ONE_CLICK_INSTALL_DIALOG = makeEventContainer('oneClickInstallDialog');
export const ANALYTICS_EVENT_CONTAINER_SITE_SELECTOR_DIALOG = makeEventContainer('siteSelectorDialog');

// Analytics Actions

export const makeAnalyticsAction = v => v;
export const ANALYTICS_ACTION_CLICKED = makeAnalyticsAction('clicked');
export const ANALYTICS_ACTION_COLLAPSED = makeAnalyticsAction('collapsed');
export const ANALYTICS_ACTION_EXPANDED = makeAnalyticsAction('expanded');
export const ANALYTICS_ACTION_FETCH_FAILED = makeAnalyticsAction('fetchFailed');
export const ANALYTICS_ACTION_REMOVED = makeAnalyticsAction('removed');
export const ANALYTICS_ACTION_REMOVE_FAILED = makeAnalyticsAction('removeFailed');
export const ANALYTICS_ACTION_RENDERED = makeAnalyticsAction('rendered');
export const ANALYTICS_ACTION_SUBMITTED = makeAnalyticsAction('submitted');
export const ANALYTICS_ACTION_CHANGED = makeAnalyticsAction('changed');
export const ANALYTICS_ACTION_TOGGLED = makeAnalyticsAction('toggled');
export const ANALYTICS_ACTION_BLURRED = makeAnalyticsAction('blurred');
export const ANALYTICS_ACTION_VIEWED = makeAnalyticsAction('viewed');
export const ANALYTICS_ACTION_ENTERED = makeAnalyticsAction('entered');

// Analytics Action Subjects

export const makeAnalyticsActionSubject = v => v;
export const ANALYTICS_SUBJECT_FORM_FIELD = makeAnalyticsActionSubject('formField');
export const ANALYTICS_SUBJECT_APP = makeAnalyticsActionSubject('app');
export const ANALYTICS_SUBJECT_BREADCRUMB = makeAnalyticsActionSubject('breadcrumb');
export const ANALYTICS_SUBJECT_BUTTON = makeAnalyticsActionSubject('button');
export const ANALYTICS_SUBJECT_LINK = makeAnalyticsActionSubject('link');
export const ANALYTICS_SUBJECT_MENU = makeAnalyticsActionSubject('menu');
export const ANALYTICS_SUBJECT_MENU_ITEM = makeAnalyticsActionSubject('menuItem');
export const ANALYTICS_SUBJECT_TAB = makeAnalyticsActionSubject('tab');
export const ANALYTICS_SUBJECT_LOZENGE = makeAnalyticsActionSubject('lozenge');
export const ANALYTICS_SUBJECT_TILE = makeAnalyticsActionSubject('tile');
export const ANALYTICS_SUBJECT_TILES = makeAnalyticsActionSubject('tiles');
export const ANALYTICS_SUBJECT_UI = makeAnalyticsActionSubject('ui');
export const ANALYTICS_SUBJECT_SEARCH = makeAnalyticsActionSubject('search');
export const ANALYTICS_SUBJECT_FILTER = makeAnalyticsActionSubject('filter');
export const ANALYTICS_SUBJECT_SORT = makeAnalyticsActionSubject('sort');
export const ANALYTICS_SUBJECT_RADIO = makeAnalyticsAction('radioButton');
export const ANALYTICS_SUBJECT_TEXT_FIELD = makeAnalyticsActionSubject('textField');
export const ANALYTICS_SUBJECT_NOT_FOUND = makeAnalyticsActionSubject('pageNotFound');
export const ANALYTICS_SUBJECT_SPONSORED_SECTION = makeAnalyticsActionSubject('sponsoredAppSection');
export const ANALYTICS_SUBJECT_MODAL = makeAnalyticsActionSubject('modal');
export const ANALYTICS_SUBJECT_ROW_ITEM = makeAnalyticsActionSubject('rowItem');
export const ANALYTICS_SUBJECT_CHECKBOX = makeAnalyticsActionSubject('checkbox');
export const ANALYTICS_SUBJECT_BANNER = makeAnalyticsActionSubject('banner');
export const ANALYTICS_SUBJECT_TOOLTIP = makeAnalyticsActionSubject('tooltip');
export const ANALYTICS_SUBJECT_ID_DISCOVER_TILE = makeAnalyticsActionSubject('discoverTile');
export const ANALYTICS_SUBJECT_ID_COLLECTION_TILES = makeAnalyticsActionSubject('collectionTile');
export const ANALYTICS_SUBJECT_IMAGE = makeAnalyticsActionSubject('image');
export const ANALYTICS_SUBJECT_IMAGE_LINK = makeAnalyticsActionSubject('imageLink');
export const ANALYTICS_SUBJECT_ID_APP_DOCUMENTATION_LINK = makeAnalyticsActionSubject('appDocumentationLink');
export const ANALYTICS_SUBJECT_ID_APP_FORUMS_LINK = makeAnalyticsActionSubject('appForumsLink');
export const ANALYTICS_SUBJECT_ID_APP_STATUS_PAGE_LINK = makeAnalyticsActionSubject('statusPageLink');
export const ANALYTICS_SUBJECT_ID_APP_ISSUE_TRACKER_LINK = makeAnalyticsActionSubject('issueTrackerLink');
export const ANALYTICS_SUBJECT_ID_COMMUNITY_QUESTION_LINK = makeAnalyticsActionSubject('communityQuestionLink');
export const ANALYTICS_SUBJECT_ID_SUPPORT_TICKET_LINK = makeAnalyticsActionSubject('supportRequestLink');
export const ANALYTICS_SUBJECT_ID_SELECT_SITE = makeAnalyticsActionSubject('selectSite');
export const ANALYTICS_SUBJECT_SEARCH_OVERLAY = makeAnalyticsActionSubject('searchOverlay');
export const ANALYTICS_SUBJECT_SEARCH_EMBEDDED = makeAnalyticsActionSubject('searchEmbedded');
export const ANALYTICS_SUBJECT_ERROR = makeAnalyticsActionSubject('error');
export const ANALYTICS_SUBJECT_NAVIGATION_ITEM = makeAnalyticsActionSubject('navigationItem');
export const ANALYTICS_SUBJECT_SCREEN = makeAnalyticsActionSubject('screenViewed');
export const ANALYTICS_SUBJECT_APP_PLANS_RENDERED = makeAnalyticsActionSubject('appPlans');

// Analytics Category Names

export const makeAnalyticsCategory = v => v;
export const ButtonClickedEvent = {
  action: ANALYTICS_ACTION_CLICKED,
  actionSubject: ANALYTICS_SUBJECT_BUTTON,
  details: 'User clicked a button',
  type: UI_EVENT_TYPE
};
export const ImageClickedEvent = {
  action: ANALYTICS_ACTION_CLICKED,
  actionSubject: ANALYTICS_SUBJECT_IMAGE,
  details: 'User clicked a image',
  type: UI_EVENT_TYPE
};
export const LinkClickedEvent = {
  action: ANALYTICS_ACTION_CLICKED,
  actionSubject: ANALYTICS_SUBJECT_LINK,
  details: 'User clicked a link',
  type: UI_EVENT_TYPE
};
export const TextFieldBlurEvent = {
  action: ANALYTICS_ACTION_BLURRED,
  actionSubject: ANALYTICS_SUBJECT_TEXT_FIELD,
  details: 'User clicked away from the text field',
  type: UI_EVENT_TYPE
};
export const RadioButtonToggledEvent = {
  action: ANALYTICS_ACTION_TOGGLED,
  actionSubject: ANALYTICS_SUBJECT_RADIO,
  details: 'User toggled a radio option',
  type: UI_EVENT_TYPE
};
export const TileClickedEvent = {
  action: ANALYTICS_ACTION_CLICKED,
  actionSubject: ANALYTICS_SUBJECT_TILE,
  details: 'User clicked a tile',
  type: UI_EVENT_TYPE
};
export const MenuExpandedEvent = {
  action: ANALYTICS_ACTION_EXPANDED,
  actionSubject: ANALYTICS_SUBJECT_MENU,
  details: 'User expanded a menu',
  type: UI_EVENT_TYPE
};
export const MenuItemClickedEvent = {
  action: ANALYTICS_ACTION_CLICKED,
  actionSubject: ANALYTICS_SUBJECT_MENU_ITEM,
  details: 'User clicked a menu item',
  type: UI_EVENT_TYPE
};
export const BreadcrumbClickedEvent = {
  action: ANALYTICS_ACTION_CLICKED,
  actionSubject: ANALYTICS_SUBJECT_BREADCRUMB,
  details: 'User clicked a breadcrumb item',
  type: UI_EVENT_TYPE
};
export const SearchSubmittedEvent = {
  action: ANALYTICS_ACTION_SUBMITTED,
  actionSubject: ANALYTICS_SUBJECT_SEARCH,
  details: 'When a search input is submitted',
  type: UI_EVENT_TYPE
};
export const FilterClickedEvent = {
  action: ANALYTICS_ACTION_CLICKED,
  actionSubject: ANALYTICS_SUBJECT_FILTER,
  details: 'User has selected a filter for the app search',
  type: UI_EVENT_TYPE
};
export const TabClickedEvent = {
  action: ANALYTICS_ACTION_CLICKED,
  actionSubject: ANALYTICS_SUBJECT_TAB,
  details: 'User clicked a tab',
  type: UI_EVENT_TYPE
};
export const LozengeClickedEvent = {
  action: ANALYTICS_ACTION_CLICKED,
  actionSubject: ANALYTICS_SUBJECT_LOZENGE,
  details: 'User clicked a lozenge',
  type: UI_EVENT_TYPE
};
export const PageNotFoundEvent = {
  action: ANALYTICS_ACTION_RENDERED,
  actionSubject: ANALYTICS_SUBJECT_NOT_FOUND,
  details: 'User landed on a not found page',
  type: UI_EVENT_TYPE
};
export const SponsoredAppEvent = {
  action: ANALYTICS_ACTION_RENDERED,
  actionSubject: ANALYTICS_SUBJECT_SPONSORED_SECTION,
  details: 'Sponsored section has been rendered',
  type: UI_EVENT_TYPE
};
export const ModalRenderedEvent = {
  action: ANALYTICS_ACTION_RENDERED,
  actionSubject: ANALYTICS_SUBJECT_MODAL,
  details: 'User opened a Modal',
  type: UI_EVENT_TYPE
};
export const CheckboxClickedEvent = {
  action: ANALYTICS_ACTION_CLICKED,
  actionSubject: ANALYTICS_SUBJECT_CHECKBOX,
  details: 'User clicked a checkbox',
  type: UI_EVENT_TYPE
};
export const BannerViewedEvent = {
  action: ANALYTICS_ACTION_VIEWED,
  actionSubject: ANALYTICS_SUBJECT_BANNER,
  details: 'User viewed a banner',
  type: UI_EVENT_TYPE
};
export const TooltipViewedEvent = {
  action: ANALYTICS_ACTION_VIEWED,
  actionSubject: ANALYTICS_SUBJECT_TOOLTIP,
  details: 'User viewed a Tooltip',
  type: UI_EVENT_TYPE
};
export const SearchOverlayViewEvent = {
  action: ANALYTICS_ACTION_VIEWED,
  actionSubject: ANALYTICS_SUBJECT_SEARCH_OVERLAY,
  details: 'User has viewed the search overlay',
  type: UI_EVENT_TYPE
};
export const SearchOverlaySuggestionClickEvent = {
  action: ANALYTICS_ACTION_CLICKED,
  actionSubject: ANALYTICS_SUBJECT_SEARCH_OVERLAY,
  details: 'User has selected a suggestion',
  type: UI_EVENT_TYPE
};
export const SearchOverlayEnterKeyEvent = {
  action: ANALYTICS_ACTION_ENTERED,
  actionSubject: ANALYTICS_SUBJECT_SEARCH_OVERLAY,
  details: 'User pressed an enter key',
  type: UI_EVENT_TYPE
};
export const SearchEmbeddedViewEvent = {
  action: ANALYTICS_ACTION_VIEWED,
  actionSubject: ANALYTICS_SUBJECT_SEARCH_EMBEDDED,
  details: 'User has viewed the search overlay',
  type: UI_EVENT_TYPE
};
export const SearchEmbeddedSuggestionClickEvent = {
  action: ANALYTICS_ACTION_CLICKED,
  actionSubject: ANALYTICS_SUBJECT_SEARCH_EMBEDDED,
  details: 'User has selected a suggestion',
  type: UI_EVENT_TYPE
};
export const TilesRenderedEvent = {
  action: ANALYTICS_ACTION_RENDERED,
  actionSubject: ANALYTICS_SUBJECT_TILES,
  details: 'Marketplace app tiles were rendered and are now visible to the user',
  type: TRACK_EVENT_TYPE
};
export const NavBarItemClickEvent = {
  action: ANALYTICS_ACTION_CLICKED,
  actionSubject: ANALYTICS_SUBJECT_NAVIGATION_ITEM,
  details: 'User clicked a navbar item',
  type: UI_EVENT_TYPE
};
export const SearchEmbeddedEnterKeyEvent = {
  action: ANALYTICS_ACTION_ENTERED,
  actionSubject: ANALYTICS_SUBJECT_SEARCH_EMBEDDED,
  details: 'User pressed an enter key',
  type: UI_EVENT_TYPE
};
export const ClearFilterEvent = {
  action: ANALYTICS_ACTION_CLICKED,
  actionSubject: ANALYTICS_SUBJECT_FILTER,
  details: 'User has cleared a selected filter for the app search',
  type: UI_EVENT_TYPE
};
export const ErrorStateEvent = {
  action: ANALYTICS_ACTION_RENDERED,
  actionSubject: ANALYTICS_SUBJECT_ERROR,
  details: 'An error state has been rendered',
  type: TRACK_EVENT_TYPE
};
export const ScreenViewedEvent = {
  action: ANALYTICS_ACTION_VIEWED,
  actionSubject: ANALYTICS_SUBJECT_SCREEN,
  details: 'User viewed screen',
  type: SCREEN_EVENT_TYPE
};
export const SortClickedEvent = {
  action: ANALYTICS_ACTION_CLICKED,
  actionSubject: ANALYTICS_SUBJECT_SORT,
  details: 'User has selected a sort option',
  type: UI_EVENT_TYPE
};
export const AppPlansRenderedEvent = {
  action: ANALYTICS_ACTION_RENDERED,
  actionSubject: ANALYTICS_SUBJECT_APP_PLANS_RENDERED,
  details: 'Marketplace store app plans rendered',
  type: TRACK_EVENT_TYPE
};
export const DropdownOptionSelectedEvent = {
  action: ANALYTICS_ACTION_CLICKED,
  actionSubject: makeAnalyticsActionSubject('dropdown'),
  details: 'User selected an option from the dropdown',
  type: UI_EVENT_TYPE
};