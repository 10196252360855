import CategoryPageRouteHandler from '../pages/category/CategoryPageRouteHandler';
import CollectionPageRouteHandler from '../pages/collections/CollectionPageRouteHandler';
import HomePageRouteHandler from './components/HomePageRoutes/HomePageRouteHandler';
import SearchResultPageRouteHandler from './components/HomePageRoutes/SearchResultPageRouteHandler';

export function getAetherRoutes() {
  return [
    {
      name: 'homeScreen',
      path: '/',
      exact: true,
      component: HomePageRouteHandler,
      hideSearchIconOnAetherNav: true,
      showAetherNavAndFooter: true,
    },
    {
      name: 'searchScreen',
      path: '/search',
      exact: true,
      component: SearchResultPageRouteHandler,
      hideSearchIconOnAetherNav: true,
      showAetherNavAndFooter: true,
    },
    {
      name: 'productHomeScreen',
      path: '/product/:productId',
      exact: true,
      component: HomePageRouteHandler,
      hideSearchIconOnAetherNav: true,
      showAetherNavAndFooter: true,
    },
    {
      name: 'categoriesScreen',
      path: '/categories/:slug/:subSlug?',
      exact: true,
      component: CategoryPageRouteHandler,
      hidePartnerLinks: true,
      showAetherNavAndFooter: true,
    },
    {
      exact: true,
      name: 'collectionScreen',
      path: '/collections/:slug',
      component: CollectionPageRouteHandler,
      hidePartnerLinks: true,
      showAetherNavAndFooter: true,
    },
    // NOTE: These routes are use for redirecting from old app page to new app page
    {
      name: 'oldProductHomeScreen',
      path: '/addons/:app/:appId?/:sortBy?',
      exact: true,
      component: HomePageRouteHandler,
      hideSearchIconOnAetherNav: true,
      showAetherNavAndFooter: true,
    },
  ];
}
