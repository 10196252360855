import { AtlassianIcon, AtlassianLogo } from '@atlaskit/logo';
import { media } from '@atlaskit/primitives/responsive';
import { token } from '@atlaskit/tokens';
import { useStatsig } from '@atlassian/mpac-feature-flags';
import { breakpointValues, useResponsiveBreakpoint } from '@atlassian/mpac-primitives';
import { LinkUtils } from '@atlassian/mpac-utils';
import { styled } from '@compiled/react';
import React from 'react';
import { Link } from 'react-router-dom';

import { useGlobalNavAnalytics } from '../Analytics/useGlobalNavAnalytics';
import { VerticalRuleWC } from '../GlobalNav.styles';

import type { History } from 'history';

import DesktopLogoSvg from '../../../../assets/images/marketplace-desktop.svg';
import MobileLogoSvg from '../../../../assets/images/marketplace-mobile.svg';

export const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
});

export const ContainerWC = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: token('space.200'),
});

export const LogoAnchor = styled.a({
  display: 'flex',
  alignItems: 'center',
  padding: `${token('space.100')} ${token('space.050')}`,
  borderRadius: token('border.radius.100', '3px'),
  // Only apply hover styles when device supports hover input (e.g. desktop devices)
  // https://developer.mozilla.org/en-US/docs/Web/CSS/@media/hover
  '@media (hover: hover)': {
    '&:hover': {
      backgroundColor: token('color.background.neutral.hovered'),
    },
  },
  '&:active': {
    backgroundColor: token('color.background.neutral.pressed'),
  },
  '&:focus-visible': {
    outline: `2px solid ${token('color.border.focused')}`,
    outlineOffset: 0,
  },
});

const AtlassianLogoAnchor = styled(Link)({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: token('space.025'),
  padding: `${token('space.100')} ${token('space.0')}`,
  borderRadius: '3px',
  [media.above.md]: {
    gap: '0.21875rem',
    width: '9.25rem',
  },
});

export const MarketplaceLogoAnchor = styled(Link)({
  display: 'flex',
  background: 'none',
  color: token('color.text'),
  border: 'none',
  borderRadius: '3px',
  fontSize: '1.375rem',
  fontStyle: 'normal',
  fontFamily: token('font.family.brand.heading'),
  fontWeight: 600,
  lineHeight: '1.25rem',
  height: '100%',
  padding: `${token('space.100')} ${token('space.0')}`,
  gap: token('space.100'),
  // Only apply hover styles when device supports hover input (e.g. desktop devices)
  // https://developer.mozilla.org/en-US/docs/Web/CSS/@media/hover
  '@media (hover: hover)': {
    '&:hover': {
      textDecoration: 'none',
      color: token('color.text.brand'),
    },
  },
  '&:active': {
    color: token('color.link.pressed'),
  },
  '&:focus-visible': {
    outline: `2px solid ${token('color.border.focused')}`,
    outlineOffset: 0,
  },
});

export type MarketplaceLogoProps = {
  history: History;
  closeMenu: () => void;
};

export const MarketplaceLogo = ({ history, closeMenu }: MarketplaceLogoProps) => {
  const { value: breakpoint } = useResponsiveBreakpoint();
  const { fireLogoClickEvent } = useGlobalNavAnalytics();
  const onClick = (e) => {
    closeMenu();
    fireLogoClickEvent();
    if (history) {
      LinkUtils.maybeOpenInNewTab(e, () => {
        history.push({ pathname: '/' });
      });
    }
  };

  const { enableMpacWebCoherence } = useStatsig();

  const isMobile = breakpoint < breakpointValues.sm;

  if (enableMpacWebCoherence()) {
    return (
      <ContainerWC>
        <AtlassianLogoAnchor
          to={{ pathname: 'https://www.atlassian.com' }}
          aria-label="Atlassian home"
          target="_blank"
          onClick={closeMenu}
        >
          {isMobile ? (
            <AtlassianIcon size="small" appearance="brand" />
          ) : (
            <AtlassianLogo size="small" appearance="brand" />
          )}
        </AtlassianLogoAnchor>
        <VerticalRuleWC />
        <MarketplaceLogoAnchor to="/" aria-label="Atlassian Marketplace home" onClick={closeMenu}>
          Marketplace
        </MarketplaceLogoAnchor>
      </ContainerWC>
    );
  }

  return (
    <Container>
      <LogoAnchor onClick={onClick} href="/" aria-label="Atlassian Marketplace home">
        <img
          src={isMobile ? MobileLogoSvg : DesktopLogoSvg}
          alt="Atlassian marketplace home"
          aria-hidden={true}
        />
      </LogoAnchor>
    </Container>
  );
};
