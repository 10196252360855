import { UrlUtils } from '@atlassian/mpac-utils';

import PartnerProfileRouteHandler from '../../../pages/partnerProfile/PartnerProfileRouteHandler';

export default () => {
  return [
    {
      name: 'vendorDetailsScreen',
      exact: true,
      hidePartnerLinks: true,
      path: UrlUtils.transformUriTemplateToRoutePath({
        template: '/vendors/{vendorId}/{vendorName}',
      }),
      component: PartnerProfileRouteHandler,
      showAetherNavAndFooter: true,
    },
    {
      name: 'vendorDetailsScreen',
      exact: true,
      hidePartnerLinks: true,
      path: UrlUtils.transformUriTemplateToRoutePath({
        template: '/vendors/{vendorId}',
      }),
      component: PartnerProfileRouteHandler,
      showAetherNavAndFooter: true,
    },
  ];
};
