import { media } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { styled } from '@compiled/react';

import { IconButton } from '../../Buttons';

export const TriggerAvatarWrapper = styled(IconButton)({
  paddingTop: token('space.050'),
  [media.above.md]: {
    margin: `0 ${token('space.050')}`,
  },
});
