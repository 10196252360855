import { MarketplaceAnalyticsContext } from '@atlassian/mpac-analytics';
import React from 'react';
import { lazyForPaint, LazySuspense } from 'react-loosely-lazy';

import BaseRouteHandler from '../common/routeHandlers/BaseRouteHandler';

const AddonSearchResultPageRouteProcessor = lazyForPaint(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "chunk-search-result-page" */ './SearchResultPageRouteProcessor'
  ).then((c) => c.default)
);

const RouteHandler = (props) => {
  return (
    <BaseRouteHandler scrollToTopOnPathChange includePartnerQuickLinks>
      <MarketplaceAnalyticsContext properties={{ source: 'searchScreen' }}>
        <LazySuspense fallback={<div />}>
          <AddonSearchResultPageRouteProcessor {...props} />
        </LazySuspense>
      </MarketplaceAnalyticsContext>
    </BaseRouteHandler>
  );
};

export default RouteHandler;
