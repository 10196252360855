/*
 * This file is meant to be isomorphic & shared between SSR & browser. We must ensure not to import
 * or add browser or SSR specific code here as much as possible.
 *
 * @see https://medium.com/airbnb-engineering/isomorphic-javascript-the-future-of-web-apps-10882b7a2ebc
 */
import { ApolloProvider } from '@apollo/client';
import { FlagsProvider } from '@atlaskit/flag';
import { setGlobalTheme } from '@atlaskit/tokens';
import { FeatureFlagsProvider, useStatsig } from '@atlassian/mpac-feature-flags';
import { NetworkStatusTracker } from '@atlassian/mpac-telemetry';
import { Source } from '@atlassian/mpac-types';
import { ErrorBoundary } from '@atlassian/mpac-ui';
import { ApplicationConfigProvider } from '@atlassian/mpac-utils';
import React from 'react';
import { ScreenClassProvider } from 'react-grid-system';
import { Helmet } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';

import AppErrorFallback from '../common/components/AppErrorFallback';
import IdentityHeartbeat from '../common/components/IdentityHeartBeat';
import Routes from './components/Routes';
import { CurrentUserProvider } from './context/CurrentUserContext';
import { GlobalStyles } from './styles/global';

import type { BootstrapConfig } from '../application-config/types/BootstrapConfig';
import type { Store } from './store';
import type { ApolloGraphQLGatewayClient } from '@atlassian/mpac-services-client/apollo-graphql-gateway';
import type { ApplicationConfigType } from '@atlassian/mpac-utils';

import './app.global.less';

export type IsomorphicAppProps = {
  bootstrapConfig: BootstrapConfig;
  apolloClient: ApolloGraphQLGatewayClient;
  reduxStore: Store;
};

/**
 * The main app component which can be mounted both on server during SSR as well as on the client.
 * Render context specific elements such as the following, must be initialized outside of this component:-
 * - Initializing the Apollo client
 * - Initializing the Redux store
 * - Fetching and initializing the bootstrap config
 *
 * `IsomorphicApp` should also be wrapped by the environment specific:-
 *  - `Router` or `ServerRouter`
 * - Browser/server specific error boundary to catch errors in above components
 */
export const IsomorphicApp = (props: IsomorphicAppProps) => {
  const { bootstrapConfig, apolloClient, reduxStore } = props;
  const { enableMpacWebCoherence } = useStatsig();
  const colorMode = window.location.search.includes('theme=dark') ? 'dark' : 'light';

  setGlobalTheme({
    colorMode,
    light: 'light',
    spacing: 'spacing',
    typography: enableMpacWebCoherence() ? 'typography-refreshed' : 'typography-adg3',
  });

  const {
    featureFlags,
    enableIdentityHeartbeats,
    environment,
    adminHubBaseUrl,
    mpacBaseUrl,
    mpacCdnBaseUrl,
    macBaseUrl,
    wacBaseUrl,
    graphQLGatewayUrl,
    atlassianCommunityBaseUrl,
  } = bootstrapConfig;

  const applicationConfig: ApplicationConfigType = {
    environment,
    source: Source.MPAC,
    algoliaConfig: bootstrapConfig.appConfig.commonConfig.algoliaConfig,
    adminHubBaseUrl,
    mpacBaseUrl,
    mpacCdnBaseUrl,
    macBaseUrl,
    wacBaseUrl,
    graphQLGatewayUrl,
    atlassianCommunityBaseUrl,
    hostUrl: mpacBaseUrl,
    stargateBaseUrl: bootstrapConfig.stargateBaseUrl,
  };

  return (
    <ErrorBoundary fallback={<AppErrorFallback />} boundaryName="IsomorphicApp">
      <GlobalStyles />
      {/* In Manage Partners we are reseting the view port to render desktop layout, this change is required to go back responsive state. */}
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0" />
      </Helmet>
      <NetworkStatusTracker>
        <ReduxProvider store={reduxStore}>
          <ApplicationConfigProvider applicationConfig={applicationConfig}>
            <ApolloProvider client={apolloClient}>
              <FeatureFlagsProvider value={featureFlags}>
                <FlagsProvider>
                  {enableIdentityHeartbeats && <IdentityHeartbeat />}
                  <ScreenClassProvider>
                    <CurrentUserProvider>
                      <Routes />
                    </CurrentUserProvider>
                  </ScreenClassProvider>
                </FlagsProvider>
              </FeatureFlagsProvider>
            </ApolloProvider>
          </ApplicationConfigProvider>
        </ReduxProvider>
      </NetworkStatusTracker>
    </ErrorBoundary>
  );
};
