import { ANALYTICS_SCREEN_MANAGE_APP_VERSION_PUBLISH } from '../analytics/Sources';
export const appVersionPublishRoute = {
  name: ANALYTICS_SCREEN_MANAGE_APP_VERSION_PUBLISH,
  path: '/manage/apps/:appId(\\d+)/versions/create',
  isNestedRoute: false
};
export const appVersionPublishFormRoute = {
  name: ANALYTICS_SCREEN_MANAGE_APP_VERSION_PUBLISH,
  path: '/manage/apps/:appId(\\d+)/versions/create/form',
  isNestedRoute: false
};