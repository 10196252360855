import Button from '@atlaskit/button';
import SearchIcon from '@atlaskit/icon/glyph/search';
import { SearchOverlay } from '@atlassian/mpac-search';
import { styled } from '@compiled/react';
import React, { useState } from 'react';

// TODO: will be removed after dogfooding of new search experience
// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
const SearchButton = styled(Button)({
  background: '#0052cc !important',
});

const GeneralTextSearch = () => {
  const [showSearchOverlay, setShowSearchOverlay] = useState(false);

  const onSubmit = (_) => null;

  const handleOnClick = () => {
    setShowSearchOverlay(true);
  };

  return (
    <form onSubmit={onSubmit}>
      <SearchButton
        onClick={handleOnClick}
        iconBefore={<SearchIcon label="search icon" />}
        appearance="primary"
        aria-label="search-button"
      >
        Search
      </SearchButton>

      {showSearchOverlay && (
        <SearchOverlay
          onClose={() => setShowSearchOverlay(false)}
          analytics={{
            source: 'navigation-bar',
          }}
        />
      )}
    </form>
  );
};

export default GeneralTextSearch;
