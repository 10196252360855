import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const baseStyles = {
  fontFamily: "var(--ds-font-family-brand-body, \"Charlie Text\", ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, \"Helvetica Neue\", sans-serif)",
  fontSize: '1rem',
  borderRadius: '4px',
  padding: "0 ".concat("var(--ds-space-200, 16px)"),
  fontWeight: 600,
  lineHeight: '1.25rem',
  alignItems: 'center'
};
export const linkOverrideStyles = _objectSpread(_objectSpread({}, baseStyles), {}, {
  color: "var(--ds-link, #0C66E4)",
  background: 'none',
  border: 'none',
  height: '1.25rem',
  padding: 0,
  '&:hover': {
    textDecoration: 'underline'
  }
});
const mpacBaseOverrides = {
  fontSize: '1rem',
  height: '2.5rem'
};
const emceeBaseOverrides = {
  fontSize: '0.875rem',
  height: '2rem'
};
export const mpacBaseStyles = _objectSpread(_objectSpread({}, baseStyles), mpacBaseOverrides);
export const emceeBaseStyles = _objectSpread(_objectSpread({}, baseStyles), emceeBaseOverrides);
const ghostStyles = _objectSpread(_objectSpread({}, baseStyles), {}, {
  background: 'transparent',
  color: "var(--ds-text-brand, #0C66E4)",
  border: "1px solid ".concat("var(--ds-border-brand, #0C66E4)"),
  '&:hover': {
    background: "var(--ds-background-selected-hovered, #CCE0FF)"
  },
  '&:active': {
    background: "var(--ds-background-selected-pressed, #85B8FF)"
  }
});
export const mpacGhostStyles = _objectSpread(_objectSpread({}, ghostStyles), mpacBaseOverrides);
export const emceeGhostStyles = _objectSpread(_objectSpread({}, ghostStyles), emceeBaseOverrides);