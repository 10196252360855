import { useQuery } from '@apollo/client';
import { useSetStatsigUser, useStatsig } from '@atlassian/mpac-feature-flags';
import { LocalStorageUtils } from '@atlassian/mpac-utils';
import { userType } from '@atlassiansox/analytics-web-client';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import * as analytics from '../../../common/utils/analytics/analytics';
import bootstrapConfig from '../../bootstrapConfig';
import { getManageDeveloperSpaceLink } from './utils';

import type { CurrentUserContextType, LoggedInUser } from '.';
import type { GetCurrentUserQuery, GetCurrentUserQueryVariables } from './CurrentUser.gql.types';
import type { ReactNode } from 'react';

import { GetCurrentUser } from './CurrentUser.graphql';

const CurrentUserContext = createContext<CurrentUserContextType>({
  loading: false,
});

type Props = {
  children: ReactNode;
};

export const CurrentUserProvider = ({ children }: Props) => {
  const { enableCurrentUserApiViaStoreBff } = useStatsig();
  const setStatsigUser = useSetStatsigUser();
  const { analyticsClient } = bootstrapConfig.get();
  const vendorId = useMemo(() => {
    return LocalStorageUtils.getFromStorage('last-visited-partner-id');
  }, []);

  const { data, loading } = useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUser,
    {
      skip: !enableCurrentUserApiViaStoreBff(),
      variables: {
        vendorId,
      },
    }
  );
  const [currentUserContext, setCurrentUserContext] = useState<CurrentUserContextType>({ loading });

  useEffect(() => {
    if (data) {
      const currentUser = data?.marketplaceStore.currentUser;
      if (currentUser.__typename === 'MarketplaceStoreLoggedInUser') {
        const loggedInUser: LoggedInUser = {
          ...currentUser,
          loggedInUserLinks: {
            ...currentUser.loggedInUserLinks,
            manageDeveloperSpace: getManageDeveloperSpaceLink(
              currentUser.lastVisitedDeveloperSpace?.vendorId
            ),
          },
        };
        setCurrentUserContext({ loggedInUser, loading });
      } else {
        setCurrentUserContext({ loading, anonymousUser: currentUser });
      }
    }
  }, [data]);

  useEffect(() => {
    if (currentUserContext.loggedInUser) {
      analyticsClient.setUserInfo(userType.ATLASSIAN_ACCOUNT, currentUserContext.loggedInUser.id);
      analytics.markHasLoggedIn();
      setStatsigUser({ atlassianAccountId: currentUserContext.loggedInUser.id });
    }
  }, [currentUserContext]);

  return (
    <CurrentUserContext.Provider value={currentUserContext}>{children}</CurrentUserContext.Provider>
  );
};

export const useCurrentUser = (): CurrentUserContextType => {
  return useContext(CurrentUserContext);
};
