import urlTemplate from 'url-template';
export const adminHubLink = function adminHubLink() {
  let adminHubBaseUrl = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return urlTemplate.parse(adminHubBaseUrl);
};
export const archivePartnerLink = function archivePartnerLink() {
  let mpacBaseUrl = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return urlTemplate.parse("".concat(mpacBaseUrl, "/archive/vendors/{partnerId}"));
};
export const appDetailsLink = function appDetailsLink() {
  let mpacBaseUrl = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return urlTemplate.parse("".concat(mpacBaseUrl, "/plugins/{appKey}/overview"));
};
export const appRatingLink = function appRatingLink() {
  let mpacBaseUrl = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return urlTemplate.parse(mpacBaseUrl + '/plugins/{appKey}/reviews');
};
export const appIconLink = function appIconLink() {
  let mpacBaseUrl = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return urlTemplate.parse(mpacBaseUrl + '/plugins/{appKey}/icon');
};
export const appListingLink = function appListingLink() {
  let mpacBaseUrl = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return urlTemplate.parse("".concat(mpacBaseUrl, "/apps/{appId}/{slug}?hosting={hosting}&tab={tab}"));
};
export const appVersionHistoryLinkWithBuildNumber = function appVersionHistoryLinkWithBuildNumber() {
  let mpacBaseUrl = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return urlTemplate.parse("".concat(mpacBaseUrl, "/apps/{appId}/{slug}/version-history#b{buildNumber}"));
};
export const archivedAppLink = function archivedAppLink() {
  let mpacBaseUrl = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return urlTemplate.parse("".concat(mpacBaseUrl, "/archive/{appId}"));
};
export const partnerDetailsLink = function partnerDetailsLink() {
  let mpacBaseUrl = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return urlTemplate.parse(mpacBaseUrl + '/vendors/{id}/{slug}');
};

/**
 * If the width & height parameters are not specified, media:
 * - Scales the images to width=1280 height=1280
 * - With default mode as "fit"
 */
export const mediaImageLink = function mediaImageLink() {
  let mpacCdnBaseUrl = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return urlTemplate.parse(mpacCdnBaseUrl + '/files/{imageId}?fileType=image&mode=full-fit');
};
export const mediaImageFromProductListingLink = function mediaImageFromProductListingLink() {
  let mpacCdnBaseUrl = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return urlTemplate.parse("".concat(mpacCdnBaseUrl, "/product-listing/files/{imageId}?fileType=image&mode=full-fit"));
};
const EmptyLinkTemplate = {
  expand: () => ''
};

/**
 * Contains templatized paths for marketplace resources
 */
export const linkTemplates = _ref => {
  let {
    adminHubBaseUrl,
    mpacBaseUrl,
    mpacCdnBaseUrl,
    macBaseUrl,
    wacBaseUrl,
    atlassianCommunityBaseUrl
  } = _ref;
  return {
    adminHub: urlTemplate.parse(adminHubBaseUrl),
    marketplaceTermsOfUse: urlTemplate.parse(wacBaseUrl + '/licensing/marketplace/termsofuse/'),
    appDetails: appDetailsLink(mpacBaseUrl),
    appPricingDetails: urlTemplate.parse(mpacBaseUrl + '/plugins/{appKey}/pricing'),
    mediaFile: urlTemplate.parse(mpacCdnBaseUrl + '/files/{imageId}'),
    mediaFileProductListing: urlTemplate.parse(mpacCdnBaseUrl + '/product-listing/files/{imageId}'),
    mediaImage: mediaImageLink(mpacCdnBaseUrl),
    mediaImageFromProductListing: mediaImageFromProductListingLink(mpacCdnBaseUrl),
    appHostingDetails: urlTemplate.parse(mpacBaseUrl + '/plugins/{appKey}/{hosting}/overview'),
    appIcon: appIconLink(mpacBaseUrl),
    appRating: appRatingLink(mpacBaseUrl),
    appManageLink: urlTemplate.parse(mpacBaseUrl + '/manage/apps/{appId}'),
    appShortLink: urlTemplate.parse(mpacBaseUrl + '/{appId}'),
    managePrivateListing: urlTemplate.parse(mpacBaseUrl + '/manage/apps/{appId}/private-listings'),
    partnerDetails: partnerDetailsLink(mpacBaseUrl),
    appCreateCommunityQuestionLink: urlTemplate.parse("".concat(atlassianCommunityBaseUrl, "/t5/custom/page/page-id/create-post-step-1?add-tags=atlassian-marketplace,addon-{appKey},{hosting}")),
    appVersionHistory: urlTemplate.parse(mpacBaseUrl + '/apps/{appId}/{appSlug}/version-history?versionHistoryHosting={hosting}'),
    artifactDownload: urlTemplate.parse(mpacBaseUrl + '/download/apps/{appId}/version/{buildNumber}'),
    descriptorDownload: urlTemplate.parse(mpacBaseUrl + '/download/apps/{appId}/version/{buildNumber}/descriptor'),
    appListingCanonical: urlTemplate.parse("".concat(mpacBaseUrl, "/apps/{appId}/{slug}")),
    appListing: appListingLink(mpacBaseUrl),
    trialLicenseActivation: macBaseUrl ? urlTemplate.parse(macBaseUrl + '/addon/try/{hamsAppKey}') : EmptyLinkTemplate,
    licensePurchase: wacBaseUrl ? urlTemplate.parse(wacBaseUrl + '/purchase/addon/{hamsAppKey}') : EmptyLinkTemplate
  };
};
export const manageAppsTabRoutes = () => ({
  // Todo - specify return type of manageAppsTabRoutes
  appPricing: urlTemplate.parse('/manage/apps/{appKey}/pricing')
});
export const isAppListingLink = link => {
  return /\/?apps\/\d+\/?/.test(link);
};