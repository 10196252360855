export const trimAfter = (str, threshold) => str.length > threshold ? "".concat(str.substring(0, threshold), "...") : str;
export const pluralize = (word, count, inclusive, pluralWord) => {
  const finalPluralWord = pluralWord ? pluralWord : "".concat(word, "s");
  const finalWord = count === 1 ? word : finalPluralWord;
  return inclusive ? "".concat(count, " ").concat(finalWord) : finalWord;
};
/**
 * Given a value, try parsing the value as JSON and return the result of the parsing as an
 * object, or return null without throwing an error.
 * @param {any} maybeJSON An input string which may or may not be JSON
 */
export function tryParseJSON(maybeJSON) {
  if (typeof maybeJSON !== 'string') {
    return {
      success: false,
      result: null
    };
  }
  try {
    const json = JSON.parse(maybeJSON);
    return {
      success: true,
      result: json
    };
  } catch (_unused) {
    return {
      success: false,
      result: null
    };
  }
}
export const removeDuplicates = arr => {
  return arr.filter((item, index) => arr.indexOf(item) === index);
};

/**
 * Given a string, removeWhiteSpaceAndCamelCase will remove white space from entire string
 * and return a string with camelCase
 * @param {string} str An input string
 */
export const removeWhiteSpaceAndCamelCase = str => {
  return str.trim().toLowerCase().replace(/([^A-Z0-9]+)(.)/gi,
  // match multiple non-letter/numbers followed by any character
  function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    return args[2].toUpperCase(); //3rd index is the character we need to transform uppercase
  });
};
export const removeEmptyTags = content => {
  return content.replace(/<(\w+)(\s*[^>]*)>\s*<\/\1>/g, '');
};