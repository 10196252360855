import ImmutablePropTypes from '@atlassian/react-immutable-proptypes';
import PropTypes from 'prop-types';

import Link from '../../common/records/Link';
import LinkTemplate from '../../common/records/LinkTemplate';
import CommonConfig from '../records/CommonConfig';
import GeneralLinks from '../records/GeneralLinks';

// @ts-expect-error [MC-2850] - TS2724 - '"immutable"' has no exported member named 'RecordOf'. Did you mean 'Record'?
import type { List, RecordOf } from 'immutable';

export default {
  generalLinks: PropTypes.instanceOf(GeneralLinks),
  commonConfig: PropTypes.instanceOf(CommonConfig),
  // eslint-disable-next-line -- Async Data is a generic function
  asyncData: (dataType) =>
    ImmutablePropTypes.contains({
      data: dataType,
      dataError: PropTypes.bool.isRequired,
      dataErrorMessages: ImmutablePropTypes.list.isRequired,
      loading: PropTypes.bool.isRequired,
    }),
  link: PropTypes.instanceOf(Link),
  linkTemplate: PropTypes.instanceOf(LinkTemplate),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

type AsyncDataRecord<DataType> = {
  data: DataType | null | undefined;
  dataError: boolean;
  dataErrorMessages: List<string>;
  loading: boolean;
};

// eslint-disable-next-line -- Async Data is a generic function
export type AsyncData<T> = RecordOf<AsyncDataRecord<T>>;
